import React from "react";
import { FlexibleDiv } from "../../../components/Box/styles";
import { Carousel, Typography } from "antd";
import { StudentsFeedbackSectionWrap, cotent } from "./styles";
import { FeedBackData } from "../../../utils/dataHelpers/feedbackData";

const StudentsFeedbackSection = () => {
  const { Title } = Typography;
  return (
    <StudentsFeedbackSectionWrap>
      <FlexibleDiv>
        <Title>Student Feedback</Title>
      </FlexibleDiv>
      <Carousel autoplay className="desktopCarousel">
        <FlexibleDiv className="carouselWrap">
          <FlexibleDiv className="carouselMainWrap">
            {FeedBackData.map((item, index) => (
              <FlexibleDiv flexDir="column" width="max-content">
                <FlexibleDiv style={cotent} className="carouselContet">
                  <img src={item.person} alt="" />
                  <p>{item.title}</p>
                </FlexibleDiv>
              </FlexibleDiv>
            ))}
          </FlexibleDiv>
        </FlexibleDiv>
        {/* <FlexibleDiv className="carouselWrap">
          <FlexibleDiv className="carouselMainWrap">
            {FeedBackData.map((item, index) => (
              <FlexibleDiv style={cotent} className="carouselContet">
                <img src={item.person} alt="" />
                <span>"</span>
                <p>{item.title}</p>
              </FlexibleDiv>
            ))}
          </FlexibleDiv>
        </FlexibleDiv> */}
        {/* <FlexibleDiv className="carouselWrap">
          <FlexibleDiv className="carouselMainWrap">
            {FeedBackData.map((item, index) => (
              <FlexibleDiv style={cotent} className="carouselContet">
                <img src={item.person} alt="" />
                <span>"</span>
                <p>{item.title}</p>
              </FlexibleDiv>
            ))}
          </FlexibleDiv>
        </FlexibleDiv> */}
        {/* <FlexibleDiv className="carouselWrap">
          <FlexibleDiv className="carouselMainWrap">
            {FeedBackData.map((item, index) => (
              <FlexibleDiv style={cotent} className="carouselContet">
                <img src={item.person} alt="" />
                <span>"</span>
                <p>{item.title}</p>
              </FlexibleDiv>
            ))}
          </FlexibleDiv>
        </FlexibleDiv> */}
      </Carousel>

      <Carousel autoplay className="mobileCarousel">
        {FeedBackData.map((item, index) => (
          <FlexibleDiv className="mobileCarouselMianWrap">
            <FlexibleDiv style={cotent} className="mobileCarouselContet">
              <img src={item.person} alt="" />
              <p>{item.title}</p>
            </FlexibleDiv>
          </FlexibleDiv>
        ))}
      </Carousel>
    </StudentsFeedbackSectionWrap>
  );
};

export default StudentsFeedbackSection;
