import React, { useState } from "react";
import { FlexibleDiv } from "../Box/styles";
import { FAQSWrap, FaqBox } from "./styles";
import { FaqData } from "../../utils/dataHelpers/faqData";
import { Link } from "react-router-dom";

const FAQS = ({ fullView }) => {
  const [showIndex, setShowIndex] = useState(0);

  return (
    <FAQSWrap>
      <FlexibleDiv className="FAQSParentWrap" flexDir="column">
        {fullView
          ? FaqData.map((item, index) => (
              <FaqBox
                showIndex={showIndex}
                index={index}
                flexDir="column"
                className="contentWrap"
                justifyContent="flex-start"
                onClick={() => {
                  index === showIndex ? setShowIndex("") : setShowIndex(index);
                }}
              >
                <FlexibleDiv
                  justifyContent="space-between"
                  height="max-content"
                  className="questionWrap"
                >
                  <p>{item.title}</p>
                  {index === showIndex ? (
                    <div>{item.minus}</div>
                  ) : (
                    <div>{item.plus}</div>
                  )}
                </FlexibleDiv>
                <FlexibleDiv
                  width="100%"
                  justifyContent="flex-start"
                  height="max-content"
                >
                  <span>
                    {item.subTitle}{" "}
                    {item.link && (
                      <Link to={item.link}>view subscription page</Link>
                    )}
                  </span>
                </FlexibleDiv>
              </FaqBox>
            ))
          : FaqData.slice(0, 3).map((item, index) => (
              <FaqBox
                showIndex={showIndex}
                index={index}
                flexDir="column"
                className="contentWrap"
                justifyContent="flex-start"
                // style={{
                //   height: index === showIndex ? "170px" : "70px",
                //   minHeight: index === showIndex && "max-content",
                // }}
                onClick={() => {
                  index === showIndex ? setShowIndex("") : setShowIndex(index);
                }}
              >
                <FlexibleDiv
                  justifyContent="space-between"
                  height="max-content"
                  className="questionWrap"
                >
                  <p>{item.title}</p>
                  {index === showIndex ? (
                    <div>{item.minus}</div>
                  ) : (
                    <div>{item.plus}</div>
                  )}
                </FlexibleDiv>
                <FlexibleDiv
                  width="100%"
                  justifyContent="flex-start"
                  height="max-content"
                >
                  <span>
                    {item.subTitle}
                    {item.link && (
                      <Link to={item.link}>view subscription page</Link>
                    )}
                  </span>
                </FlexibleDiv>
              </FaqBox>
            ))}
      </FlexibleDiv>
    </FAQSWrap>
  );
};

export default FAQS;
