import React from "react";
import { FooterMenuList } from "../../../utils/dataHelpers/landingMenuItems";
import { FooterSocialsList } from "../../../utils/dataHelpers/footerSocialLinks";
import { FooterWrap } from "./styles";
import { FlexibleDiv } from "../../Box/styles";
import Logo from "../../../assets/pngs/whiteTextLogo.png";
import GoogleTranslate from "../../Translator";
import { nanoid } from "nanoid";

const LandingFooter = () => {
  const routeTo = (path) => {
    window.location.replace(path);
  };

  return (
    <FooterWrap>
      <FlexibleDiv className="landingPageFooter">
        <FlexibleDiv
          justifyContent="flex-start"
          className="landingPageFooter__sub"
          height="100%"
          flexWrap="no-wrap"
          alignItems="flex-start"
          margin="20px"
        >
          <div className="landingPageFooter__logoWrap">
            <img alt="" src={Logo} />
          </div>

          <p>
            Exam Padi is the companion you need to prepare for your entrance and
            school-leaving examinations.
            <br />
            <br />
            It is an e-learning platform hosting learning videos and
            computer-based tests to help JS3 and SS3 students (exit class)
            prepare for Junior and Senior WAEC, NECO, JAMB, and SAT.
            <br />
            <br />
            It also caters to primary school students writing the Common
            Entrance examination, providing video lessons and computer-based
            tests.
          </p>
          <FlexibleDiv
            width="max-content"
            className="landingPageFooter__itemsWrap"
          >
            <ul>
              {FooterMenuList.map((item, index) => (
                <li
                  key={nanoid()}
                  onClick={() => {
                    routeTo(item.to);
                  }}
                >
                  {item.title}
                </li>
              ))}
            </ul>
            <FlexibleDiv justifyContent="flex-start" className="translateWrap">
              <GoogleTranslate />
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>
        <FlexibleDiv
          justifyContent="space-between"
          className="landingPageFooter__lastSection"
          flexWrap="no-wrap"
          height="50px"
          width="90%"
        >
          <small>Exam Padi © 2021 All rights reserved.</small>
          <FlexibleDiv
            width="150px"
            justifyContent="space-evenly"
            height="max-content"
          >
            {FooterSocialsList.map((item, index) => (
              // <Menu.>
              <a href={item.to} key={index} target="__blank">
                {item.icon}
              </a>
              // </Menu.Item>
            ))}
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </FooterWrap>
  );
};

export default LandingFooter;
