import React from "react";
import LandingLayout from "../../components/LandingLayout";
import { FlexibleDiv } from "../../components/Box/styles";
import { PrivacyPolicyWrap } from "./styles";
import { Typography } from "antd";

const PrivacyPolicyPage = () => {
  const { Title, Text } = Typography;
  return (
    <LandingLayout>
      <PrivacyPolicyWrap>
        <FlexibleDiv>
          <FlexibleDiv
            flexDir="column"
            alignItems="flex-start"
            width="70%"
            className="parentWrap"
          >
            <Title level={3}>Welcome to the Privacy Notice for Exam Padi</Title>

            <Text>
              This privacy notice explains how Exam Padi collects and handles
              your data because of your use of our products, including any
              information you supply when you use one of our products, sign up
              for our newsletters, or participate in one of our competitions.{" "}
              <br /> <br />
              We want you to know that we value you highly and that your
              information is treated with the utmost care and privacy. Please
              contact us if you have any questions about how this data is used.
            </Text>

            <Title level={5}>Consent</Title>
            <Text>
              You agree to the collection and use of information in line with
              this Notice by accessing or using Our Services or Products. You
              may change your mind and withdraw your consent at any time by
              writing us at <a href="mailto: us@exampadi.ng">us@exampadi.ng</a>.
              Please note that withdrawing your consent will not impact the
              lawfulness of any processing done before you withdraw your
              consent.
            </Text>

            <Title level={5}>The Data We Collect About You</Title>
            <Text>
              We collect information about you when you sign up on Our Platform.
              This data could include the following: Name, username or alias,
              e-mail address, mailing address, location, class (in school), age,
              and phone number are all examples of profile data. Our payment
              service providers may additionally demand financial information
              such as your credit/debit card number, billing address, and
              payment options. Technical information, such as the type of mobile
              device and browser you use, your computer's IP address,
              information about the pages you visit, a mobile device ID or
              unique identifier, page view statistics, standard Web log data,
              still and moving photos, and so on; Marketing data, such as user
              feedback; and Usage data, such as the amount of time spent on Our
              app, when it was opened, device data, and learning statistics
              (time spent learning, lessons viewed, test scores, etc.)
            </Text>

            <Title level={5}>How We Use Your Personal Data</Title>
            <Text>
              We will only use your personal information for the purposes for
              which it was gathered, which may include:{" "}
            </Text>
            <ul>
              <li>Registering you as a new user.</li>
              <li>To take care of your order and deliver it.</li>

              <li>
                To help us identify and provide the type of service offerings in
                which you are most interested;
              </li>

              <li>
                To enable us to create the most relevant content for you and to
                generally improve Our services.
              </li>

              <li>To manage your relationship with us. </li>

              <li>
                To recommend products or services that may be of interest to
                you.
              </li>

              <li>
                To help us identify and provide the type of service offerings in
                which you are most interested.{" "}
              </li>

              <li>
                To enable us to create the most relevant content for you and to
                generally improve Our services.
              </li>

              <li>
                To manage risk, or to detect, prevent, and/or remediate fraud or
                other potentially prohibited or illegal activities.
              </li>
              <li>
                To send periodic emails and marketing materials regarding your
                interest in Our products and services;
              </li>
              <li>
                To send periodic emails and marketing materials regarding your
                interest in Our products and services.
              </li>
            </ul>

            <Title level={5}>Third-Party Links</Title>
            <Text>
              We may incorporate or offer third-party items or services on Our
              Platform from time to time. As a result, Our Platform may include
              connections to websites that are owned and maintained by others.
              These third-party websites may have their own unique and
              independent privacy rules that regulate how your data is collected
              and processed. We strongly advise you to check their privacy
              policies because they will supersede this Notice. As a result, we
              disclaim any responsibility or liability for the content,
              activity, or privacy policies of third-party websites.
            </Text>

            <Title level={5}>Cookies</Title>
            <Text>
              If you accept, Our Website will set cookies (small files
              comprising a string of characters) on your computer. These cookies
              enable us to distinguish you from other users of Our Website by
              uniquely identifying your browser. This allows us to keep track of
              your preferences and give you a tailored and seamless browsing
              experience on Our Website. We utilize so-called "analytical"
              cookies. They enable us to detect and count the number of visitors
              as well as see how they navigate the Website while on it. This
              enables us to improve the way Our Website functions, such as
              ensuring that people can quickly locate what they are looking for.
              We do not divulge any information.
            </Text>

            <Title level={5}>User Profiles</Title>
            <Text>
              Every logged-in user has their profile. A unique personal
              identification number is provided to each profile, allowing us to
              ensure that only you have access to it.
              <br /> <br />
              When you register, we construct your profile, assign a personal
              identification number (your User ID), and then send this personal
              identification number together with an activation link to your
              email address. This is a one-of-a-kind code. It's your ticket to
              smooth transit around Our Platform, allowing you to use it without
              having to fill out registration forms with information you've
              already given. You won't have to re-register if you transfer
              computers; simply use your User ID
            </Text>

            <Title level={5}>Data Security</Title>
            <Text>
              Your personal information is stored and processed on our systems
              in Nigeria. If We need to transmit your data to another nation,
              that country's data protection laws must be adequate. Please rest
              assured that We have implemented suitable security measures, such
              as access restrictions, firewalls, data encryption, and physical
              protection, to prevent your personal information from being lost,
              altered, disclosed, or used in an unauthorized manner.
              Furthermore, only those employees, agents, and contractors with a
              business need to know to have access to your data. These
              employees, agents, and contractors have a responsibility to always
              maintain confidentiality and will do so regularly.
              <br /> <br />
              Also, please be aware that We have procedures in place to deal
              with any suspected personal data breach, and that We will notify
              you and any applicable regulator if We are legally obligated to do
              so.
            </Text>

            <Title level={5}>Retention of Personal Information</Title>
            <Text>
              We will store your personal information for as long as it is
              needed and following the purpose for which it was collected,
              including for tax, accounting, regulatory, and legal purposes. We
              will preserve your personal information for as long as we need it
              to handle or respond to any complaints, questions, or issues you
              may have about Our connection with you. In addition, in the event
              of a complaint or if We reasonably believe there is a risk of
              litigation in connection with Our relationship with you, We may
              keep your data for a longer period.
              <br /> <br />
              You may notify us at any time if you no longer want to hear from
              us, and we will preserve only the bare minimum of information upon
              receiving such notice to guarantee that we do not contact you
              again. When there is no longer a legal, business, or user
              necessity for it to be retained, we will actively examine the
              personal information we hold and securely delete it, or in some
              cases anonymize it.
            </Text>

            <Title level={5}>Your Legal Rights</Title>
            <Text>You have the right to:</Text>
            <ul>
              <li>
                Ask for access to your personal information; (commonly known as
                a "data subject access request"). This allows you to obtain a
                copy of the personal information We have on you and verify that
                We are processing it lawfully. Please be aware that a request
                for access to data subjects may be subject to an administrative
                cost.
              </li>
              <li>
                Request that We correct the personal information we have on you.
                This allows you to have any incomplete or erroneous information
                We have about you rectified, however, We may need to verify the
                veracity of the new information you submit.
              </li>

              <li>
                Request erasure of your data. This enables you to ask us to
                delete or remove personal data where there is no good reason for
                us to continue to process it. You also have the right to ask us
                to delete or remove your data where you have successfully
                exercised your right to object to processing (see below), where
                We may have processed your information unlawfully or where We
                are required to erase your data to comply with local law. Note,
                however, that We may not always be able to comply with your
                request of erasure for specific legal reasons which will be
                notified to you, if applicable, at the time of your request;
              </li>

              <li>
                You have the right to object to the processing of your data if
                We are relying on a legitimate interest (or those of a third
                party) and there is something about your specific situation that
                makes you want to object to processing on this ground because
                you believe it infringes on your fundamental rights and
                freedoms. You also have the right to object if your data is
                being processed for direct marketing purposes. In rare
                situations, We may be able to show that We have compelling legal
                grounds to process your data that outweigh your rights and
                freedoms.
              </li>

              <li>
                Request that your data be transferred to you or a third party.
                We shall give your data in a structured, frequently used,
                machine-readable manner to you or a third party you specify.
                This privilege only applies to automated information that you
                permitted us to use or if we utilized the information to fulfill
                a contract with you. Please be aware that there may be an
                administrative cost associated with this request.{" "}
              </li>

              <li>
                Where We rely on consent to process your data, you can withdraw
                your consent at any time. However, the lawfulness of any
                processing carried out before you withdraw your consent will not
                be affected. We may not be able to offer you some products or
                services if you withdraw your consent. If this is the case, we
                will notify you when you withdraw your consent.
              </li>
            </ul>

            <Title level={5}>Security</Title>
            <Text>
              We use a range of reasonable security measures at Exam Padi to
              preserve the confidentiality and integrity of your personal
              information.
              <br /> <br />
              We have installed rigorous controls and security precautions at
              the technological and operational levels to prevent unauthorized
              access to your information. To ensure secure transmission of your
              data, our Platform employs Transport Layer Security (SSL/TLS).
              Once you've successfully logged into the platform, the padlock
              icon should appear in your URL address bar. The URL address will
              also begin with https://, indicating that the page is safe. <br />{" "}
              <br />
              Please keep in mind that you have a crucial role to play in the
              security of your data. Without your username and password, no one
              can read or update your personal information, so don't give them
              away. We cannot, however, guarantee that security breaches will
              never occur because the internet is not a secure medium. As a
              result, we are not liable for problems such as hacking and other
              unauthorized third-party actions that violate Our reasonable
              security procedures.
            </Text>

            <Title level={5}>Online Privacy Notice Only</Title>
            <Text>
              This online Notice only applies to data acquired on Our Platform;
              it does not apply to data obtained offline.
            </Text>

            <Title level={5}>Changes to Our Privacy Policy</Title>
            <Text>
              We reserve the right to change or amend our Privacy Policy at any
              time, and if we do, we will update this page. Every time you
              submit information to us or use Our services, it is your duty to
              review the Privacy Policy. You will be deemed to have agreed to
              any such changes if you continue to use the site. Exam Padi will
              tell you through email or a pop-up or redirection when you log in
              to our Platform if the purpose is for processing change or if a
              revision is a material.
            </Text>

            <Title level={5}>Disclaimer</Title>
            <Text>
              We do not represent or warrant the state or functionality of Our
              platform(s), their fitness for use, or that Our service will be
              free of interruptions or errors as a result of this Policy. There
              shall be no duty or responsibility for any risks associated with
              the use of Our Platform, including but not limited to any risk of
              damage to your computer, software, or data caused by any virus,
              program, or other file transmitted or activated by Our Platform or
              your access to it. We also cannot guarantee the accuracy of the
              information on Our Platform, particularly content shared by
              third-party users.
            </Text>
          </FlexibleDiv>
        </FlexibleDiv>
      </PrivacyPolicyWrap>
    </LandingLayout>
  );
};

export default PrivacyPolicyPage;
