import React from "react";
import LandingLayout from "../../components/LandingLayout";
import { FlexibleDiv } from "../../components/Box/styles";
import { TermsAndConditionsWrap } from "./styles";
import { Typography } from "antd";

const TermsAndConditionsPage = () => {
  const { Title, Text } = Typography;
  return (
    <LandingLayout>
      <TermsAndConditionsWrap>
        <FlexibleDiv>
          <FlexibleDiv
            flexDir="column"
            alignItems="flex-start"
            width="70%"
            className="parentWrap"
          >
            <Title level={3}>
              Welcome to the Terms and Conditions for Exam Padi
            </Title>

            <Text>
              These terms and conditions outline the rules and regulations for
              the use of Exam Padi's Website, located at www.exampadi.ng, and
              Application.
              <br /> <br />
              By accessing these platforms, we assume you accept these terms and
              conditions. Do not continue to use Exam Padi if you do not agree
              to take all the terms and conditions stated on this page.
              <br /> <br />
              The following terminology applies to these Terms and Conditions,
              Privacy Statement and Disclaimer Notice and all Agreements:
              "Client", "You" and "Your" refers to you, the person log on this
              website and compliant to the Company’s terms and conditions. "The
              Company", "Ourselves", "We", "Our" and "Us", refers to our
              Company. "Party", "Parties", or "Us", refers to both the Client
              and ourselves. All terms refer to the offer, acceptance, and
              consideration of payment necessary to undertake the process of our
              assistance to the Client in the most appropriate manner for the
              express purpose of meeting the Client’s needs in respect of the
              provision of the Company’s stated services, following and subject
              to, prevailing law of The Federal Republic of Nigeria. Any use of
              the above terminology or other words in the singular, plural,
              capitalization, and/or he/she or they, are taken as
              interchangeable and therefore as referring to same.
            </Text>

            <Title level={5}>Cookies</Title>
            <Text>
              We employ the use of cookies. By accessing Exam Padi, you agreed
              to use cookies in agreement with Exam Padi's Privacy Policy.
              <br /> <br />
              Most interactive websites use cookies to let us retrieve the
              user’s details for each visit. Cookies are used by our website to
              enable the functionality of certain areas to make it easier for
              people visiting our website. Some of our affiliate/advertising
              partners may also use cookies.
            </Text>

            <Title level={5}>Acceptance of Terms</Title>
            <Text>
              This Agreement shall begin on the date hereof. You accept and
              agree to be bound and abide by these Terms and Our Privacy Notice
              by signing up for Our services. You must not sign up for Our
              services if You do not agree to these Terms and/or the Privacy
              Notice. If you are operating on behalf of a minor, these Terms
              apply to the minor as well. You should read these Terms carefully
              since any use, access, or browsing of Our Services or Products,
              whether for a fee or without, and with or without a subscription,
              through any means signifies Your acceptance of the Terms and
              commitment to be legally bound by them
              <br /> <br />
              You will be subject to the Terms issued by these third-party
              service providers when utilizing services offered by Exam Padi
              third-party service providers, such as email. You agree to those
              extra Terms by registering for any of Our Services or Products. By
              emailing us at{" "}
              <a href="mailto: info@exampadi.ng">info@exampadi.ng</a> with the
              subject line "Request for Terms of Service Agreement," you can
              request a written copy of this Agreement. Furthermore, when
              utilizing specific Exam Padi Services, You and Exam Padi are bound
              by any stated guidelines or restrictions that may apply to such
              services from time to time. All these recommendations or norms are
              hereby included in the Terms by reference.
            </Text>

            <Title level={5}>Subscription</Title>
            <Text>
              Our services are billed on a subscription basis (the
              “Subscription”). This means that once Your Subscription expires,
              you will no longer have access to Our materials. To renew your
              subscription, send a request to us via email to{" "}
              <a href="mailto: support@exampadi.ng">support@exampadi.ng </a>or
              via phone at +234 905 381 3763. If You provide credit or debit
              card information to pay for the Service, you represent and warrant
              that You are authorized to supply such information and that You
              authorize Us to charge that payment method on a subscription basis
              to pay the fees as they come due.
              <br /> <br />
              Every effort has been made to ensure accurate pricing of Our
              products but please be aware that Our prices are subject to
              change. If a Product is ordered, and the listed price has changed,
              you will be notified before processing Your Order. You agree to
              pay all applicable taxes relating to Your use of Our Services or
              Products.
            </Text>

            <Title level={5}>
              Subscriptions purchased and managed through the Google Play store.
            </Title>
            <Text>
              You can use your Google ID to purchase a subscription if you
              purchased the app through the Google Play Store. Subscriptions are
              purchased using the Play store’s in-app purchase system. Please
              keep in mind that your subscription will automatically renew at
              the end of the current period. Depending on the plan you chose,
              your subscription may automatically renew every 30, 90, or 365
              days. You can reach us by email at{" "}
              <a href="mailto: support@exampadi.ng">support@exampadi.ng </a> or
              by phone at +234 905 381 3763 if you have any questions or need
              further information.
              <br /> <br />
              You can cancel your subscription at any time and avoid future
              charges by going to Settings Google ID and canceling at least a
              day before each renewal date. It's worth noting that your plan
              will automatically renew until you cancel it. You will also get
              access to your Account for the duration of the pre-paid period.
              You can reach us by email at{" "}
              <a href="mailto: support@exampadi.ng">support@exampadi.ng </a> or
              by phone at +234 905 381 3763 if you have any questions or need
              further information.
            </Text>

            <Title level={5}>
              Subscriptions purchased and managed through the Apple store.
            </Title>
            <Text>
              You can use your Apple ID to purchase a subscription if you
              purchased the app through the App Store. Subscriptions are
              purchased using the App store’s in-app purchase system. Please
              keep in mind that your subscription will automatically renew at
              the end of the current period. Depending on the plan you chose,
              your subscription may automatically renew every 30, 90, or 365
              days. You can reach us by email at{" "}
              <a href="mailto: support@exampadi.ng">support@exampadi.ng </a> or
              by phone at +234 905 381 3763 if you have any questions or need
              further information.
              <br /> <br />
              You can cancel your subscription at any time and avoid future
              charges by going to Settings Apple ID and cancelling at least a
              day before each renewal date. It's worth noting that your plan
              will automatically renew until you cancel it. You will also get
              access to your Account for the duration of the pre-paid period.
              You can reach us by email at{" "}
              <a href="mailto: support@exampadi.ng">support@exampadi.ng </a> or
              by phone at +234 905 381 3763 if you have any questions or need
              further information.
            </Text>

            <Title level={5}>User Behaviour</Title>
            <Text>
              This Website may only be used for lawful purposes. As a result, a
              User shall not post, send, or transmit any material that violates
              or infringes in any way on the rights of others, or perform any
              act that is unlawful, threatening, abusive, defamatory, invasive
              of privacy or publicity rights, vulgar, obscene, profane, or
              otherwise objectionable, or that encourages conducts that would
              constitute a criminal offense, give rise to civil liability, or
              that encourages conducts that would constitute a criminal offense
              <br /> <br />
              Any User behaviour that, in the sole judgement of exam padi,
              limits or inhibits another user from using or enjoying this
              Website is banned. As a result, no User may use this Website to
              advertise or perform any commercial solicitation, including, but
              not limited to, soliciting Users to become subscribers of other
              online information services that compete or are likely to compete
              with Exam Padi
              <br /> <br />
              Note that Exam Padi reserves the right to deny You access to any
              of the Services or Products, or any portion thereof, without
              notice for the following reasons: (a) immediately by Exam Padi in
              the event of any unauthorized access or use by You; (b)
              immediately by Exam Padi if You assign or transfer (or attempt to
              assign or transfer) any rights granted to You under this
              Agreement.
            </Text>

            <Title level={5}>Intellectual Property Rights (IP)</Title>
            <Text>
              All current and future rights in and to trade secrets, patents,
              copyrights, trademarks, service marks, know-how, and other
              proprietary rights of any kind under the laws of any governmental
              authority, domestic or foreign, shall vest in Exam Padi, including
              rights in and to all applications and registrations relating to
              the services provided by this Website (the "Intellectual Property
              Rights").
              <br /> <br />
              All intellectual property rights are reserved. You may access this
              from Exam Padi for your personal use subject to restrictions set
              in these terms and conditions. with Exam Padi
              <br /> <br />
              You must not:
            </Text>
            <ul>
              <li>Republish material from Exam Padi</li>
              <li>Sell, rent, or sub-license material from Exam Padi</li>

              <li>Reproduce, duplicate, or copy material from Exam Padi</li>

              <li>Redistribute content from Exam Padi</li>
            </ul>
            <Text>
              Computer programs, technology, data files, techniques, algorithms,
              methods, codes, logos, marks, trade secrets, designs, text,
              graphics, pictures, audio and video files, other data or
              copyrightable materials or content, and their selection and
              arrangement are all protected as a collective work or compilation
              under Nigerian copyright and other resale rights. Except for
              third-party materials used to develop content on our website, all
              individual content, modules, articles, and other elements,
              including all software available on this Website or used to
              construct and operate this Website, are and will remain the
              property of Exam Padi.
              <br /> <br />
              All trademarks and service mark displayed or mentioned on this
              Website, whether registered or unregistered, as well as product
              names and business names or logos, are the property of their
              respective owners. A User may only use such marks with the owner's
              prior written permission. Any mention of a product, service,
              process, or other pieces of information, whether by trade name,
              trademark, manufacturer, supplier, or otherwise, does not imply
              that Exam Padi endorses, sponsors, or recommends it.
              <br /> <br />
              You undertake to follow all applicable copyright laws as well as
              any other copyright notices or limitations on this Website. You
              acknowledge that Exam Padi and its partners created, compiled,
              prepared, revised, selected, and arranged this Website and that it
              is their intellectual property. During and after the time of these
              Terms, you promise to preserve the intellectual rights of Exam
              Padi and all parties with rights in this Website and to comply
              with all reasonable written demands made by Exam Padi or its
              suppliers and licensors of contents ("Affiliates")
              <br /> <br />
              You agree to alert Exam Padi in writing as soon as you become
              aware of any illegal access or use of this Website by any
              individual or entity, to protect Your copyright as well as the
              copyright of other Users. All current and future rights to, and
              ownership to, this Website (including the right to exploit this
              Website and any portions of it using any current or future
              technology) are reserved for Exam Padi’s sole use. You may not
              copy or make any use of this Website or any portion of it without
              prior written permission from Exam Padi except as expressly
              permitted by these Terms.
              <br /> <br />
              You may not upload, post, or otherwise make available on this
              Website any content protected by copyright, trademark, or other
              proprietary rights without the express permission of the owner of
              the copyright, trademark, or other proprietary rights, and You
              bear the responsibility for determining whether any material is
              protected by copyright. Any loss resulting from any infringement
              of copyrights, proprietary rights, or any other harm coming from
              such a contribution is your responsibility.
              <br /> <br />
              By submitting content to this Website, you automatically grant or
              warrant that the owner of that content has expressly granted you a
              royalty-free, perpetual, irrevocable right and license to use it.
            </Text>

            <Title level={5}>Utilization of Services</Title>
            <Text>
              Our Product, Website, Application, and Services are for Your
              personal, non-commercial use only. It is forbidden to use the
              Application, Website, Services, or Products, or their contents,
              for anything other than personal use. Our Application, Website,
              Products, and/or Services are subject to the following
              restrictions when used for personal or non-commercial purposes:
            </Text>
            <ul>
              <li>
                Use any material or information made available through the
                Services, including images or photographs, in any way that
                violates the owner's copyright, trademark, patent, trade secret,
                or other proprietary rights.
              </li>
              <li>
                Upload files containing viruses, malware, worms, damaged files,
                or any other similar software or applications that could harm
                our systems' operation.
              </li>
              <li>
                Falsify or remove any copyright management information in a file
                uploaded to Our Website/Application, such as author
                attributions, legal or other proper notices indicating the moral
                intellectual property right of another, or proprietary
                designations or labels of the origin or source of software or
                other material.
              </li>
              <li>
                Use deep-links, page-scraping, robots, spiders, or any other
                automatic device, program, algorithm, or methodology, or any
                similar or equivalent manual process, to increase traffic to the
                Application/Website, to access, acquire, copy, or monitor any
                portion of the Application/Website, or to in any way reproduce
                or circumvent the navigational structure or presentation of the
                Application/Website or any content.
              </li>
              <li>
                Restriction or impediment to any other User's use and enjoyment
                of the Services or Products.
              </li>{" "}
              <li>
                Any code of conduct or other guidelines that may apply to a
                certain Service or Product is violated
              </li>{" "}
              <li>Any applicable laws or regulations are violated.</li>
              <li>
                Restriction or impediment to any other User's use and enjoyment
                of the Services or Products.
              </li>{" "}
              <li>
                Any code of conduct or other guidelines that may apply to a
                certain Service or Product is violated
              </li>{" "}
              <li>
                Use, download, or otherwise duplicate any directory of Users of
                the Services or other User or usage information, or any portion
                thereof, to a person or entity (whether for a{" "}
              </li>
            </ul>
            <Text>
              We reserve the right to monitor any User's submissions, feedback,
              and changes on this Website and to remove any items placed for an
              illegal purpose, as well as suspend that User
            </Text>

            <Title level={5}>Termination</Title>
            <Text>
              You can cancel or suspend your subscription at any moment by
              visiting the profile management page. We will deactivate Your
              account and remove all materials uploaded or communicated to Us
              during Your use of Our Products and Services after termination.
              You will not be charged for the next subscription month after We
              confirm termination, and You will not be entitled to a return of
              any money already paid for any billing period unless you request a
              refund within the authorized refund request window.
              <br />
              <br /> We may cancel Your subscription after your billing cycle by
              giving you at least 14 days' notice in writing. Exam Padi may also
              terminate these Terms at any time and without notice before the
              end of a billing month if:
            </Text>
            <ul>
              <li>
                You have used the Services in a way that exposes Us to legal
                liability or disrupts the use of Our Services by other users.
              </li>
              <li>
                You have used this Website for any of the prohibited purposes or
                uploaded any of the prohibited materials.
              </li>
              <li>We are investigating suspected misconduct by You. </li>
              <li>
                You have used the Services in a way that exposes Us to legal
                liability or disrupts the use of Our Services by other users.
              </li>
              <li>
                You have used this Website/Application for any of the prohibited
                purposes or uploaded any of the prohibited materials.
              </li>{" "}
            </ul>
            <Text>
              We will try to offer You an advance warning and a chance to export
              a copy of Your Content from Our Website if We limit, suspend, or
              terminate Our services. However, if You violate these Terms or any
              relevant legislation, we may elect to suspend Your account
              immediately and without notice.
              <br />
              <br />
              We will make commercially reasonable efforts to reduce the breadth
              and duration of any limitation or suspension imposed following
              these Terms and as necessary to address the issue that triggered
              the action. Exam Padi may keep Your Content after it has been
              terminated, subject to the provisions of the law on retention or
              the right to be forgotten.
            </Text>

            <Title level={5}>Modification of Terms</Title>
            <Text>
              If We limit, suspend, or discontinue Our services, we will
              endeavour to give You advance notice and the opportunity to export
              a copy of Your Content from Our Website. If You breach these Terms
              or any applicable law, we reserve the right to suspend Your
              account immediately and without warning.
              <br />
              <br /> We will use commercially reasonable efforts to limit the
              scope and length of any limitation or suspension imposed following
              these Terms, as needed to resolve the issue that prompted the
              action. Subject to the restrictions of the legislation on
              retention or the right to be forgotten, Exam Padi may preserve
              Your Content after it has been ended.
            </Text>

            <Title level={5}>
              Notices Concerning Software Documents and Services on this Website{" "}
            </Title>
            <Text>Disclaimer of warranty and limitation of liability: </Text>
            <ul>
              <li>
                The User acknowledges that his or her usage of this Website is
                at their own risk. Exam Padi, its affiliates, and any of Our
                respective employees, agents, third-party service providers, or
                licensors make no warranty that this Website will be
                uninterrupted or error-free, nor do they make any warranty as to
                the results that may be obtained from using this Website, or as
                to the accuracy, reliability, or content of any information and
                service provided through this Website.
              </li>
              <li>
                This Website is provided "as is," with no express or implied
                warranties of any kind, including, but not limited to,
                warranties of title or implied warranties of merchantability or
                fitness for a particular purpose, except to the extent that such
                warranties are implied by and incapable of exclusion,
                restriction, or modification under the laws applicable to these
                Terms.
              </li>
              <li>
                This warranty disclaimer and liability limitation applies to any
                damages or injuries caused by any failure of performance, error,
                omission, interruption, deletion, defect, delay in operation or
                transmission, computer virus, communication line failure, theft
                or destruction, or unauthorized access to, alteration of, or use
                of, this website.{" "}
              </li>
              <li>
                Despite our best efforts to provide current and accurate
                information in the development of Our
                Application/Website/Services/Products, we disclaim liability for
                any errors or inaccuracies that may arise or be contained in any
                material. We, nor any third parties, make any warranty or
                guarantee as to the accuracy, timeliness, performance,
                completeness, or appropriateness for a specific purpose of the
                information and materials found or offered on
                Application/Website/Services/Products.rs.
              </li>
              <li>
                At any moment, we have the right to remove, review, edit, or
                delete any content. In addition, some of the content in the
                Application/Services/Website/Products may contain personal
                thoughts and viewpoints. Exam Padi will not be held liable.
              </li>{" "}
              <li>
                We may contact Our Users through SMS, email, or phone to provide
                information about Our Products, as well as notices of essential
                changes, and/or to obtain permission to demonstrate Our
                products. You agree to receive these emails regularly.
              </li>{" "}
              <li>
                Exam Padi may contact You via the registered mobile number or
                e-mail address, or any other mobile number, contact number, or
                email address supplied by You, to facilitate the effective
                provision of Our Services. The User fully authorizes Exam
                Padi to contact them, as well as any students using the
                Services, through the above-mentioned methods at any time after
                registration.
              </li>{" "}
              <li>
                Only certain devices/tablets/instruments/hardware are compatible
                with Exam Padi's Products and/or Services. Exam Padi is not
                required to supply viable products and/or services for any
                instruments that are not recognized by Exam Padi or that are not
                compatible with Exam Padi's products and services. Furthermore,
                You agree that any problem relating to any
                devices/tablets/instruments/hardware used to access Our Services
                or Products must be reported to the manufacturer, whose contact
                information is listed on the box, and Exam Padi will not be held
                liable in any way for such defect.
              </li>{" "}
              <li>
                Our products and services are not available to minors,
                un-discharged, or insolvent individuals. If a minor wish qwqto
                use Our products or Services, his or her legal guardian or
                parents must agree to these Terms before allowing the minor to
                do so. It is presumed that a juvenile who uses Our Services has
                gained the agreement of their legal guardian or parents and that
                the legal guardian or parents have made such use available. Exam
                Padi will not be held liable for any consequences arising from
                the misuse of any of Our applications, products, or services
                that may occur because of any individual, including a juvenile,
                registering for the Services/Products.
              </li>{" "}
            </ul>

            <Title level={5}>Account, Password, and Security for Members</Title>
            <Text>
              We are dedicated to protecting the privacy of Your personal
              information. When You sign up for Our services, You must create an
              account by supplying Us with current, complete, and accurate
              information as directed on the registration form. <br />
              <br />
              Please keep in mind that You are solely responsible for any
              activities that occur under Your Account, and You must contact Us
              immediately if there is any unauthorized use of Your account or
              any other security breach. Exam Padi is not responsible for any
              losses incurred because of third-party access, whether with or
              without Your knowledge. <br />
              <br /> We have safeguards in place to secure Your personal
              information from unauthorized access. However, while we strive to
              provide acceptable security for the information we collect and
              process, we acknowledge that no security system can prevent all
              potential security breaches, and as a result, we cannot guarantee
              the total security of Your information.
            </Text>

            <Title level={5}>Miscellaneous</Title>
            <Text>a. &nbsp; Unforeseen circumstances:</Text>
            <p>
              We will not be liable for any failure or delay in performance
              caused by events beyond Our control, such as acts of God, war,
              riot, acts of civil or military authorities, fire, floods,
              accidents, service outages caused by equipment and/or software
              failure and/or telecommunications failures, power failures,
              network failures, failures of third-party service providers, and
              so on (including providers of internet services and
              telecommunications). <br />
              <br />
              If You are impacted by one of these events, you must report Us
              within seven (7) days of becoming aware of it. The execution of
              these Terms will be halted for as long as any such incident
              prevents Us from carrying out Our responsibilities under these
              Terms.
            </p>

            <Text>b. &nbsp; Links to Other Websites:</Text>
            <p>
              Links to third-party websites will take you away from this site.
              Exam Padi has no control over the connected websites, and we are
              not responsible for the contents of any linked website, any link
              contained in a linked website, or any changes or updates to such
              Websites. Exam Padi is providing these links to You as a courtesy,
              and the inclusion of any link does not mean that Exam
              Padi endorses the third-party website.
            </p>

            <Text>c. &nbsp; Governing Law:</Text>
            <p>
              The laws of the Federal Republic of Nigeria shall govern and be
              construed in conformity with these Terms.
            </p>

            <Text>d. &nbsp; Indemnification:</Text>
            <p>
              User agrees to defend, indemnify, and hold Exam Padi, its
              affiliates, and their respective directors, officers, employees,
              and agents harmless from and against any claims and expenses,
              including attorneys' fees, arising out of User's or User's Account
              usage of Exam Padi.
            </p>

            <Text>e. &nbsp; References:</Text>
            <p>
              The names of the companies, organizations, goods, domain names,
              email addresses, logos, individuals, and events represented in
              this article are all made up. There is no intentional or implied
              association with any real company, organization, product, domain
              name, e-mail address, logo, person, or event.
            </p>

            <Text>e. &nbsp; Complete Agreement:</Text>
            <p>
              These terms and conditions, together with Our Privacy Notice, form
              the whole agreement between You and Us concerning Your use of Our
              Website and supersede any prior agreements.
              <br />
              <br />
              Any rights given in these Terms that are not expressly granted in
              these Terms are reserved.
            </p>

            <Title level={5}>Please get in touch with us.</Title>
            <Text>
              Please contact Us at{" "}
              <a href="mailto: info@exampadi.ng">info@exampadi.ng</a> if You
              have any questions regarding this terms and conditions.
            </Text>
          </FlexibleDiv>
        </FlexibleDiv>
      </TermsAndConditionsWrap>
    </LandingLayout>
  );
};

export default TermsAndConditionsPage;
