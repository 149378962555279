import Image1 from "../../assets/images/1.jpg";
import Image2 from "../../assets/images/2.jpg";
import Image3 from "../../assets/images/3.jpg";
import Image4 from "../../assets/images/4.jpg";
import Image5 from "../../assets/images/5.jpg";
import Image6 from "../../assets/images/6.jpg";
import Image7 from "../../assets/images/7.jpg";
import Image8 from "../../assets/images/8.jpg";
import Image9 from "../../assets/images/9.jpg";
import Image10 from "../../assets/images/10.jpg";
import Image11 from "../../assets/images/11.jpg";

export const ShopItems = [
  {
    id: "9ad3177893",
    name: "Exam Padi Education Tablet",
    price: 100000,
    currency: "₦",
    title: "Configuration",
    desc: "Tablets/Laptops",
    perks: [
      "9.7inch IPS screen",
      "4gb Ram",
      "32gb storage with expandable memory card slot",
      "Android 11 Operating System",
      "One year free subscription to the Exam Padi Application",
      "Free Shipping to all locations across Nigeria",
    ],
    images: [
      Image1,
      Image2,
      Image3,
      Image4,
      Image5,
      Image6,
      Image7,
      Image8,
      Image9,
      Image10,
      Image11,
    ],
  },
];
