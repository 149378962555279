import React from "react";
import LandingLayout from "../../components/LandingLayout";
import { SubscriptionCard, SubscriptionWrap } from "./styles";
import { Typography } from "antd";
import { FlexibleDiv } from "../../components/Box/styles";
import TakeLessonsWithYou from "../../components/TakeLessonsWithYou";
import {
  SubscriptionPlans,
  NewSubscriptionPlans,
} from "../../utils/dataHelpers/subscriptionPlans";
import Bubbles from "../../assets/pngs/bubbleBackground.png";
import Check from "../../assets/svgs/check.svg";

const Subscription = () => {
  const { Title, Text } = Typography;

  return (
    <LandingLayout>
      <SubscriptionWrap>
        <FlexibleDiv>
          <FlexibleDiv className="cardswrap">
            <Title level={3}>Choose a subscription plan</Title>
            <Text className="text">
              Purchase a subscription plan and gain access to a library of
              learning content
            </Text>
            <FlexibleDiv className="sub-card__wrapper">
              {NewSubscriptionPlans.map((plan, index) => (
                <SubscriptionCard
                  key={index}
                  style={{
                    backgroundColor: `rgb(${plan.colorRGB})`,
                    border: `2px solid ${plan.color}`,
                  }}
                  color={plan.colorRGB}
                >
                  <Title level={5} style={{ color: plan.textColor }}>
                    {plan.title}
                  </Title>
                  <div className="pricing">
                    <Title level={3} style={{ color: plan.textColor }}>
                      {plan.amount}
                    </Title>
                    <Text style={{ color: plan.textColor }}>
                      {" "}
                      /{plan.duration}
                    </Text>
                  </div>

                  <ol className="perks">
                    {plan.perks.map((perk, index) => (
                      <li
                        className="perk"
                        key={index}
                        style={{ color: plan.textColor }}
                      >
                        <span className="checkmark">&#x2713;</span>
                        <span>{perk}</span>
                      </li>
                    ))}
                  </ol>
                </SubscriptionCard>
              ))}
            </FlexibleDiv>
          </FlexibleDiv>
          <Title level={2}>
            Download the Exampadi App to buy a subscription plan and gain access
            to a library of content
          </Title>
          <FlexibleDiv>
            <TakeLessonsWithYou />
          </FlexibleDiv>
        </FlexibleDiv>
        <img src={Bubbles} alt="" className="backgroundBubbles" />
      </SubscriptionWrap>
    </LandingLayout>
  );
};

export default Subscription;
