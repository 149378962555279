import React from "react";
import { TwitterOutlined } from "@ant-design/icons";
import { FaFacebook as Facebook } from "react-icons/fa";
import { ImMail4 as Message } from "react-icons/im";
import { GrLinkedin as Linkedin } from "react-icons/gr";

export const FooterSocialsList = [
  {
    to: "/",
    icon: <TwitterOutlined />,
  },
  {
    to: "/",
    icon: <Facebook />,
  },
  {
    to: "/",
    icon: <Message />,
  },
  {
    to: "/",
    icon: <Linkedin />,
  },
];
