import styled from "styled-components";

export const FooterWrap = styled.div`
  width: 100%;
  height: max-content;
  background: #2b0434;
  padding: 30px 50px;
  overflow-x: hidden;
  transition: all 0.3s ease;

  .landingPageFooter {
    width: 100%;
    height: 100%;

    .landingPageFooter__logoWrap {
      width: 150px;
      height: 160px;
      max-width: 150px;
      max-height: 160px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      @media (max-width: 800px) {
        display: none;
      }
    }
    p {
      font-size: 14px;
      line-height: 20px;
      color: #e0cce4;
      padding: 10px;
      margin: 0 30px;
      width: 45%;
    }
    .translateWrap {
      max-width: 350px;
    }
    ul {
      width: max-content;
      display: flex;
      justify-content: space-between;
      max-width: 350px;
      flex-wrap: wrap;
      background: transparent;
      border: none;

      li {
        padding: 10px 0;
        transition: all 0.3s ease;
        position: relative;
        width: 150px;
        text-align: start;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.03em;
        color: #ffffff;
        cursor: pointer;

        :hover {
          opacity: 0.7;
        }
      }
    }
  }
  .landingPageFooter__lastSection {
    small {
      font-weight: bold;
      font-size: 10px;
      color: #e0cce4;
      letter-spacing: 0.5px;
    }

    a {
      color: white;
      transition: all 0.3s ease;
      width: 25px;
      height: 25px;
      font-size: 15px;

      @media (min-width: 800px) {
        :hover {
          font-size: 25px;
          opacity: 0.7;
          margin-top: -10px;
        }
      }
    }
  }
  @media (max-width: 1200px) {
    padding: 30px;
    .landingPageFooter__sub {
      margin: 0;
    }
  }
  @media (max-width: 800px) {
    p {
      width: 50%;
    }
  }

  @media (max-width: 650px) {
    padding: 30px 0;
    .landingPageFooter__sub {
      flex-wrap: wrap;
      justify-content: center;
      margin: 0;

      p {
        width: 90%;
        margin: 0 0 20px 0;
      }
      .landingPageFooter__itemsWrap {
        width: 100%;

        ul,
        .translateWrap {
          justify-content: space-between;
          width: 90%;
          max-width: 100%;
          a {
            font-size: 14px;
          }
        }
      }
    }
    .landingPageFooter__lastSection {
      padding-left: 5%;
    }
  }

  @media (max-width: 430px) {
    .landingPageFooter__lastSection {
      flex-direction: column-reverse;
      margin-top: 30px;
      height: 50px;
    }
  }
`;
