import styled from "styled-components";

export const SuccessContainer = styled.main`
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Mulish:wght@600&display=swap");
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-image: url("/images/success-bg.png");
  background-position: center;
  background-size: cover;
  padding: 86px 111px 0 111px;
  position: relative;
  @media screen and (max-width: 768px) {
    padding: 40px 50px;
  }
`;

export const Modal = styled.section`
  width: 90%;
  max-width: 520px;
  min-height: 600px;
  box-shadow: 0px 5.21327px 24px rgba(47, 45, 81, 0.1);
  border-radius: 15.6398px;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 480px) {
    padding: 40px 20px;
    min-height: 70vh;
  }
`;

export const ModalHeading = styled.h1`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 35px;
  letter-spacing: -0.05em;
  margin-top: 40px;
  @media screen and (max-width: 480px) {
    font-size: 1.3rem;
    line-height: 20px;
  }
`;

export const ModalText = styled.p`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 27px;
  text-align: center;
  margin-top: 14px;
  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
    line-height: 20px;
    margin-top: 4px;
  }
`;

export const ModalButton = styled.button`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.14rem;
  line-height: 20px;
  text-align: center;
  margin-top: 14px;
  color: #ffffff;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2f2d51;
  border: 1px solid #2f2d51;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #ffffff;
    border: 1px solid #2f2d51;
    color: #2f2d51;
  }
  @media screen and (max-width: 480px) {
    font-size: 1rem;
    line-height: 20px;
    margin-top: 4px;
    height: 60px;
    border-radius: 17px;
  }
`;
