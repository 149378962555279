import React from "react";
import LandingLayout from "../../components/LandingLayout";
import HeroSection from "./HeroSection";
import AboutSection from "./AboutSection";
import StartLearningSection from "./StartLearning";
import WinSection from "./WinSection";
// import TakeLessonsWithYou from "../../components/TakeLessonsWithYou";
import FAQSection from "./FaqSection";
import StudentsFeedbackSection from "./StudentsFeedback";

const LandingPage = () => {
  return (
    <LandingLayout>
      <HeroSection />
      <AboutSection />
      <StartLearningSection />
      <WinSection />
      {/* <TakeLessonsWithYou id="download" /> */}
      <StudentsFeedbackSection />
      <FAQSection />
    </LandingLayout>
  );
};

export default LandingPage;
