
import styled, { keyframes } from "styled-components";

// Flexible box
export const ArrowAnimation = styled("div")`
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const ArrowAnimation2 = styled("div")`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .arrowSliding{
        position: absolute;

        .arrow{
            width: 50px;
            height: 50px;
            border: 20px solid;
            border-color: black transparent transparent black;
        }

        &.delay1{
            -webkit-animation-delay: 1s;
            animation-delay: 1s;
        }
        &.delay2{
            -webkit-animation-delay: 2s;
            animation-delay: 2s;
        }
        &.delay3{
            -webkit-animation-delay: 3s;
            animation-delay: 3s;
        }
        &.right{
            -webkit-animation: slide-right 4s linear infinite;
            animation: slide-right 4s linear infinite;
        }
        &.left{
            -webkit-animation: slide-left 4s linear infinite;
            animation: slide-left 4s linear infinite;
        }
    }
`;

export const AnimatedArrowWrapper = styled("div")`
    width: 100%;
    display: ${props => props.swiped ? "none" : "flex"};
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 9999999999;

    span {
        font-size: 2rem;
        font-weight: 600;
        line-height: 0;
        color: white;
        -webkit-text-stroke: 1px #F8F8F8;
        text-shadow: 0px 1px 4px #4d2456;
    }
`;

export const Arrow = styled("div")`
    width: 25px;
    height: 25px;
    border: 5px solid;
    border-color: #4d2456 transparent transparent #4d2456;
    transform: ${props => props.right ? "rotate(135deg)" : props.left ? "rotate(-45deg)" : ""};
`;

const SlideLeft = keyframes`
    0% { opacity:0; transform: translateX(20px); }		
    20% { opacity:0; transform: translateX(-20px); }	
    80% { opacity:1; transform: translateX(-40px); }	
    100% { opacity:0; transform: translateX(-100px); }	
`;

const SlideRight = keyframes`
    0% { opacity:0; transform: translateX(-20px); }		
    20% { opacity:0; transform: translateX(20px); }	
    80% { opacity:1; transform: translateX(40px); }	
    100% { opacity:0; transform: translateX(100px); }		
`;

export const ArrowSliding = styled("div")`
    position: absolute;

    &.delay1{
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
    }
    &.delay2{
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
    }
    &.delay3{
        -webkit-animation-delay: 3s;
        animation-delay: 3s;
    }
    &.right{
        animation-name: ${SlideRight};
        animation-duration: 4s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
    &.left{
        animation-name: ${SlideLeft};
        animation-duration: 4s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
`;