export const Bubble = ({ height, width, color }) => (
  <svg
    height={height || "33"}
    fill={color || "#FF6600"}
    width={width || "34"}
    viewBox="0 0 34 33"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.816 19.4082C29.9435 13.856 26.425 6.78956 17.1241 5.99858C-10.1337 3.68447 16.4127 49.166 27.816 19.4082Z"
      fill={color || "#FF6600"}
    />
  </svg>
);
