import React from "react";
import Celebration from "../../assets/svgs/celebrate.svg";
import Logo from "../../assets/svgs/logo.svg";
import {
  Modal,
  ModalButton,
  ModalHeading,
  ModalText,
  SuccessContainer,
} from "./styles";

const Success = () => {
  return (
    <SuccessContainer>
      <img width={70} height={90} alt="" src={Logo} />
      <Modal>
        <img src={Celebration} alt="" />
        <ModalHeading>Payment Successfull!!</ModalHeading>
        <ModalText>
          Congratulations, you have successfully unlocked your lesson package
        </ModalText>
        <ModalButton>Continue</ModalButton>
      </Modal>
    </SuccessContainer>
  );
};

export default Success;
