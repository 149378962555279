export const SubscriptionPlans = [
  {
    title: "PRIMARY (COMMON ENTRANCE)",
    color: "#313848",
    monthly: "Monthly - NGN 1000",
    quaterly: "Quarterly - NGN 1,500",
    yearly: "Yearly - NGN 3,500",
    content:
      "This package contains video lessons and CBT practice questions for the primary school level preparing for common entrance exam.",
  },
  {
    title: "JUNIOR (BECE)",
    color: "#6BBB99",
    monthly: "Monthly - NGN 1500",
    quaterly: "Quarterly - NGN 2,500",
    yearly: "Yearly - NGN 5,500",
    content:
      "This package contains video lessons and CBT practice questions for the JUNIOR secondary school level preparing to write the BECE exam.",
  },
  {
    title: "SENIOR (WAEC, NECO, JAMB, SAT)",
    color: "#FAA96A",
    monthly: "Monthly - NGN 2000",
    quaterly: "Quarterly - NGN 3000",
    yearly: "Yearly - NGN 10,500",
    content:
      "This package contains video lessons, WAEC, NECO, JAMB and SAT past questions for the SENIOR secondary school level.",
  },
  {
    title: "FULL SUBSCRIPTION",
    color: "#7C82D8",
    monthly: "Monthly - NGN 2,500",
    quaterly: "Quarterly - NGN 5,500",
    yearly: "Yearly - NGN 15,000",
    content:
      "This package is a combination of all the other packages. Subscribe and you get access to the primary, junior and secondary content.",
  },
];


export const NewSubscriptionPlans = [
  {
    title: "Free Plan",
    amount: "₦0",
    duration: "Lifetime",
    perks: ["Short Lesson Videos", "1 year of CBT per course"],
    color: "#7C82D8",
    colorRGB: "124, 130, 216",
    textColor: "#ffffff",
  },
  {
    title: "Daily Plan",
    amount: "₦300",
    duration: "Day",
    perks: ["Full Access to Lesson Videos", "CBTs and Test Score history for 24hrs"],
    color: "#6BBB99",
    colorRGB: "107, 187, 153",
    textColor: "#334245",
  },
  {
    title: "Weekly Plan",
    amount: "₦1,000",
    duration: "Week",
    perks: ["Full Access to Lesson Videos", "CBTs and Test Score history for 7 days"],
    color: "#2F2D51",
    colorRGB: "47, 45, 81",
    textColor: "#ffffff",

  },
  {
    title: "Monthly Plan",
    amount: "₦2,000",
    duration: "Month",
    perks: ["Full Access to Lesson Videos", "CBTs and Test Score history for 30 days"],
    color: "#FAA96A",
    colorRGB: "250, 169, 106",
    textColor: "#252430",
  },
  {
    title: "Yearly Plan",
    amount: "₦20,000",
    duration: "Year",
    perks: ["Full Access to Lesson Videos", "CBTs and Test Score history for 365 days"],
    color: "#3C4F3D",
    colorRGB: "60, 79, 61",
    textColor: "#FFFFFF",
  },
]