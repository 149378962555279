import React, { useEffect, useRef, useState } from "react";
import { FlexibleDiv } from "../Box/styles";
import { TakeLessonsWithYouWrap } from "./styles";
import { Typography } from "antd";
import PhoneInHand from "../../assets/pngs/phoneInHand.png";
import Windows from "../../assets/pngs/windows.png";
import Android from "../../assets/pngs/android.png";
import Apple from "../../assets/pngs/apple.png";
import DesktopDownloadModal from "../DesktopDownloadModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TakeLessonsWithYou = () => {
  const [showModal, setShowModal] = useState(false);
  const { Text, Title } = Typography;

  const { location } = useHistory();
  const takeLessonsWithYou = useRef();

  const convertLocationSearchToObject = (location) => {
    console.log({ location });
    const queryString = location;

    const cleanedQueryString = queryString.startsWith("?")
      ? queryString.slice(1)
      : queryString;

    const keyValuePairs = cleanedQueryString.split("&");

    const result = {};
    keyValuePairs.forEach((keyValue) => {
      const [key, value] = keyValue.split("=");
      result[key] = value;
    });

    return result;
  };

  useEffect(() => {
    const locationObject = convertLocationSearchToObject(location.search);
    if (
      locationObject.f === "link" &&
      locationObject.t === "code" &&
      locationObject.platform === "win"
    ) {
      takeLessonsWithYou.current.scrollIntoView({ behavior: "smooth" });
      setShowModal(true);
    }
  }, [location]);

  return (
    <TakeLessonsWithYouWrap>
      <FlexibleDiv className="takeLessonsWithYou" margin="0 0 40px 0">
        <FlexibleDiv
          className="takeLessonsWithYou__card"
          height="max-content"
          justifyContent="space-evenly"
          flexDir="column-reverse"
        >
          <FlexibleDiv
            width="60%"
            height="auto"
            className="takeLessonsWithYou__cardImage"
          >
            <img src={PhoneInHand} alt="" />
          </FlexibleDiv>
          <FlexibleDiv
            flexDir="column"
            width="80%"
            justifyContent="flex-start"
            className="takeLessonsWithYou__cardBody"
            id="takeLessonsWithYou"
            ref={takeLessonsWithYou}
          >
            <Title>Take Your Lessons With You</Title>
            <Text className="subHeader">Download the Exampadi App</Text>
            <FlexibleDiv
              className="takeLessonsWithYou__linksWrap"
              justifyContent="space-between"
              flexDir="column"
              width="450px"
              height="max-content"
            >
              <FlexibleDiv flexDir="column" width="max-content">
                <Text className="takeLessonsWithYou__linksHeaderText">
                  On Mobile
                </Text>
                <FlexibleDiv justifyContent="space-between">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.exampadi"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FlexibleDiv
                      className="takeLessonsWithYou__links"
                      width="120px"
                      justifyContent="space-evenly"
                      height="35px"
                    >
                      <img src={Android} alt="" /> <span>Android</span>
                    </FlexibleDiv>
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/exam-padi/id6443403192"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FlexibleDiv
                      className="takeLessonsWithYou__links"
                      width="120px"
                      justifyContent="space-evenly"
                      height="35px"
                    >
                      <img src={Apple} alt="" /> <span>iOS</span>
                    </FlexibleDiv>
                  </a>
                </FlexibleDiv>
              </FlexibleDiv>
              <FlexibleDiv flexDir="column" width="max-content">
                <Text className="takeLessonsWithYou__linksHeaderText">
                  On Desktop
                </Text>
                <small
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <FlexibleDiv
                    className="takeLessonsWithYou__links one"
                    width="120px"
                    justifyContent="space-evenly"
                    height="35px"
                  >
                    <img src={Windows} alt="" /> <span>Windows</span>
                  </FlexibleDiv>
                </small>
              </FlexibleDiv>
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
      <DesktopDownloadModal showModal={showModal} setShowModal={setShowModal} />
    </TakeLessonsWithYouWrap>
  );
};

export default TakeLessonsWithYou;
