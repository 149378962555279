import React, { useContext, useEffect } from "react";
import { FlexibleDiv } from "../../../components/Box/styles";
import { ProductWrap, ShopWrap } from "../styles";
import { ShopItems } from "../../../utils/dataHelpers/shopData";
import { Card, Typography } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ShopContext } from "../../../context/ShopProvider";

const RelatedProducts = ({ selectedItem }) => {
  const { Text, Title } = Typography;
  const { Meta } = Card;

  const navigate = useHistory();
  const { setCartInfo } = useContext(ShopContext);

  const navigateToProduct = (itemId) => {
    setCartInfo((prev) => ({ ...prev, selectedProduct: itemId }));
    navigate.push(`/shop/products/${itemId}`);
  };

  useEffect(() => {
    console.log({ selectedItem });
  }, [selectedItem]);

  return (
    <FlexibleDiv>
      <FlexibleDiv className="related-products">
        <Title level={3}> Related Products</Title>
        <ProductWrap>
          {ShopItems.map(
            (item, index) =>
              item.id !== selectedItem.id && (
                <Card
                  key={index}
                  hoverable
                  style={{ width: 240, alignSelf: "flex-start" }}
                  cover={<img alt="example" src={item.images[4]} />}
                  onClick={() => navigateToProduct(item.id)}
                >
                  <Meta title={item.name} description={item.desc} />
                </Card>
              )
          )}
        </ProductWrap>
      </FlexibleDiv>
    </FlexibleDiv>
  );
};

export default RelatedProducts;
