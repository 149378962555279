import { BrowserRouter, Route, Switch } from "react-router-dom";
import ContactUs from "./routes/ContactPage";
import AboutUs from "./routes/AboutPage";
import Subscription from "./routes/SubscriptionPage";
import FAQPage from "./routes/FAQPage";
import LandingPage from "./routes/LandingPage";
import Testing from "./routes/testing";
import Login from "./routes/LoginPage";
import ScrollToTop from "./components/ScrollManager";
import Signup from "./routes/SignUpPage";
import PrivacyPolicyPage from "./routes/PrivacyPolicy";
import TermsAndConditionsPage from "./routes/TermsAndConditions";
import Success from "./routes/SuccessPage";
import BuyTabletPage from "./routes/BuyTabletPage";
import EmailVerificationPage from "./routes/EmailVerifiedPage";
import DeleteAccount from "./routes/DeleteAccount";
import WemaVerificationPage from "./routes/WemaIOSVerification";
import ChatWidget from "./components/ChatWidget";
import RedirectToSKMShop from "./routes/RedirectToSKMShop";
import Shop from "./routes/Shop";
import ClearSession from "./routes/ClearSession";
import MainProductView from "./routes/Shop/_components/MainProductView";
import Checkout from "./routes/Shop/Checkout";
import Cart from "./routes/Shop/Cart";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route path="/clear-session" component={ClearSession} />
          <Route path="/support/live-chat" component={ChatWidget} />
          <Route path="/testing" component={Testing} />
          <Route path="/contact" component={ContactUs} />
          <Route path="/login" component={Login} />
          <Route path="/signup" component={Signup} />
          <Route path="/faq" component={FAQPage} />
          <Route path="/shop/products/:productId" component={MainProductView} />
          <Route path="/shop/cart/checkout" component={Checkout} />
          <Route path="/shop/cart" component={Cart} />
          <Route path="/shop" component={Shop} />
          <Route path="/subscription" component={Subscription} />
          <Route path="/exampadi-tab" component={BuyTabletPage} />
          <Route path="/shop-redirect" component={RedirectToSKMShop} />
          <Route path="/about" component={AboutUs} />
          <Route path="/privacy-policy" component={PrivacyPolicyPage} />
          <Route path="/success" component={Success} />
          <Route
            path="/terms-and-conditions"
            component={TermsAndConditionsPage}
          />
          <Route path="/email-verification" component={EmailVerificationPage} />
          <Route path="/delete-my-account" component={DeleteAccount} />
          <Route path="/wema-verification" component={WemaVerificationPage} />
          <Route path="/" component={LandingPage} />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
