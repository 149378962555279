import styled from "styled-components";

const StyledClearSession = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid #eee;
    border-top: 5px solid #333;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg) scale(1.25);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default StyledClearSession;
