import React from "react";
import { FlexibleDiv } from "../Box/styles";
import { ModalWrapper } from "./styles";
import { Typography } from "antd";

const DesktopDownloadModal = ({ showModal, setShowModal }) => {
  const { Text } = Typography;

  return (
    <ModalWrapper
      title=""
      visible={showModal}
      onCancel={() => {
        setShowModal(false);
      }}
      footer={null}
      centered={true}
    >
      <FlexibleDiv flexDir="column" className="modalCntentWrap">
        <Text
          className="header"
          onClick={() => {
            setShowModal(false);
          }}
        >
          download{" "}
          <a download href="https://exampadi.ng/64-bit-build.zip">
            exampadi.64-bit-build.zip
          </a>{" "}
          here
        </Text>

        <Text
          className="header"
          onClick={() => {
            setShowModal(false);
          }}
        >
          download{" "}
          <a download href="https://exampadi.ng/32-bit-build.zip">
            exampadi.32-bit-build.zip
          </a>{" "}
          here
        </Text>
      </FlexibleDiv>
    </ModalWrapper>
  );
};

export default DesktopDownloadModal;
