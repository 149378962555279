import styled from "styled-components";

export const EmailVerifiedWrap = styled.div`
  width: 100%;
  min-height: fit-content;

  .largeHeader {
    height: 300px;
    background: #4d2456;
    position: relative;

    @media (max-width: 600px) {
      height: 200px;
    }

    h1 {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 150px;
      letter-spacing: 0.03em;
      color: #c4c4c4;
      opacity: 0.3;
      margin: 0;
      -webkit-text-stroke: 3px #fefefe;

      font {
        font-family: Montserrat;
      }
      @media (max-width: 900px) {
        font-size: 120px;
      }
      @media (max-width: 800px) {
        font-size: 100px;
      }
      @media (max-width: 600px) {
        font-size: 70px;
      }
      @media (max-width: 400px) {
        text-align: center;
      }
    }
    h3 {
      font-weight: bold;
      font-size: 46px;
      letter-spacing: 0.03em;
      color: #ffffff;
      position: absolute;
      left: 8%;
      bottom: 15%;

      @media (max-width: 1000px) {
        left: 3%;
      }
      @media (max-width: 800px) {
        font-size: 30px;
        bottom: 45%;
      }
      @media (max-width: 600px) {
        font-size: 20px;
      }
    }

    img {
      @media (max-width: 1100px) {
        width: 100%;
      }
      @media (max-width: 1100px) {
        width: 90%;
      }
      @media (max-width: 800px) {
        width: 60%;
      }
    }
  }
  .message{
    margin: 3rem 0;
    font-size: 1.2rem;

    @media (max-width: 800px) {
      padding: 0 1rem;
    }

  }
`;
