import styled from "styled-components";
// import BackgroundImage from "../../../assets/pngs/heroSectionBackground.png";

export const AboutSectionWrap = styled.div`
  width: 100%;
  height: max-content;
  padding: 60px 0;
  @media (max-width: 800px) {
    padding: 100px 0 120px 0;
  }

  .aboutSectionParentWrap {
    @media (max-width: 800px) {
      flex-direction: column-reverse;
    }
    .aboutSectionImageWrap {
      width: 700px;
      height: 500px;
      max-width: 700px;
      max-height: 500px;
      background: #4d2456;
      border-radius: 33px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @media (max-width: 800px) {
        width: 85%;
        height: auto;
        max-width: 85%;
        max-height: auto;
        margin-top: 50px;

        img {
          object-fit: contain;
        }
      }
      @media (max-width: 500px) {
        border-radius: 20px;
      }
    }

    .aboutSectionTextWrap {
      width: 700px;
      height: max-content;
      margin: 120px 0 80px 0;

      h1 {
        width: 200px;
        line-height: 45px;
        font-size: 35px;
      }
      .aboutTextSmall {
        width: 450px;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.03em;
        color: #4f4f4f;
      }
      @media (max-width: 800px) {
        width: 85%;
        margin: 0;
        h1 {
          width: 100%;
          text-align: center;
          font-size: 24px;
          margin-bottom: 20px;
        }
        .aboutTextSmall {
          width: 100%;
        }
      }
    }
  }
`;
