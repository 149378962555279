import styled from "styled-components";
import LineBg from "../../assets/pngs/subscriptionPageBg.png";

export const BuyTabletPageWrap = styled.div`
  width: 100%;
  position: relative;
  min-height: 100vh;
  padding-bottom: 100px;

  @media (max-width: 800px) {
    padding-bottom: 150px;
  }
  .cardswrap {
    background-position: 100% 100%;
    background-image: url(${LineBg});
    padding: 150px 0 0 0;
    flex-direction: column;

    & > div {
      @media (max-width: 800px) {
        flex-direction: column;
      }
    }

    .tabletdisplaywrap {
      width: min(100% - 40px, 1200px);
      min-height: 15rem;
      margin: -10px auto 0;
      padding: 15px 20px 30px;
      background-color: #4d2456;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;

      h1 {
        font-family: "Permanent Marker", cursive;
        font-weight: 400;
        font-size: 50px;
        text-transform: capitalize;
        letter-spacing: -0.04em;
        line-height: 50px;
        max-width: 420px;

        font {
          font-family: "Permanent Marker", cursive;
        }
        @media (max-width: 800px) {
          text-align: center;
        }
        @media (max-width: 450px) {
          font-size: 40px;
          width: 100%;
        }
        span {
          font-family: "Permanent Marker", cursive;
        }
      }

      .tablbetimg {
        width: 50%;
        display: flex;
        position: relative;

        @media (max-width: 800px) {
          display: none;
        }

        & > img {
          z-index: 10;
        }

        img {
          width: 65%;
          object-fit: contain;
        }
        .tabletImgbg {
          position: absolute;
          background-color: white;
          width: clamp(16rem, 5rem + 22vw, 25rem);
          height: clamp(16rem, 5rem + 22vw, 25rem);
          border-radius: 100%;
        }

        .tablet-circle{
          width: 380px;
          height: 380px;
          border-radius: 50%;
          background-color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;

          img{
            width: 80%;
          }
        }
      }

      .tabletcaption {
        width: 50%;
        min-height: 400px;
        padding: 1rem 2rem;
        text-align: left;
        display: flex;
        justify-content: flex-start;

        @media (max-width: 800px) {
          width: 100%;
          justify-content: center;
        }

        .tabletcaption__leftDiv__sub {
          h1,
          h1 span {
            font-size: clamp(1.4rem, 1.6rem + 2vw, 3rem);
          }

          span {
            font-size: clamp(0.9rem, 1rem + 1vw, 1.5rem);
          }

          button {
            font-size: clamp(0.9rem, 1rem + 1vw, 1.5rem);
            font-weight: 500;
          }

          @media (max-width: 800px) {
            text-align: center;
            justify-content: center;
            align-items: center;
          }
        }

        h1 {
          color: #fff;
        }

      }
    }

    .buy {
      width: min(100% - 40px, 1200px);

      .specifications {
        justify-content: center;
        align-items: center;
        margin-top: 4rem;
        gap: 1rem @media (max-width: 800px) {
          flex-direction: column;
        }

        .spec_img {
          flex-grow: 1;
          .swiper {
            max-width: 400px;
            height: 480px;
            margin: 0;

            @media (max-width: 800px) {
              max-width: 300px;
              height: 380px;
            }
          }

          .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 18px;
            font-size: 22px;
            font-weight: bold;
            color: #fff;
            background-color: #fff;
            position: relative;
            overflow: hidden;

            #lens {
              position: absolute;
              border: 2px solid grey;
              border-radius: 50%;
              overflow: hidden;
              cursor: none;
              width: 200px;
              height: 200px;
              box-shadow: inset 0 0 10px 2px grey;
              filter: drop-shadow(0 0 2px grey);
            }
            #zoom img {
              width: 100%;
              height: 100%;
            }
            #lens > * {
              cursor: none;
            }

            img {
              flex-shrink: 0;
              width: 85%;
              min-height: 100%;
            }
          }
          .swiper-slide:nth-child(odd) {
            background-color: #f0f0f0;
          }
          .swiper-slide:nth-child(even) {
            background-color: #eee;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            transform: scale(1.2);

            @media (max-width: 800px) {
              width: 60%;
              height: 60%;
            }

            @media (max-width: 490px) {
              width: 50%;
            }
          }
          @media (max-width: 800px) {
            width: 100%;
          }
        }
        .card_wrapper {
          gap: 1rem;
          padding: 1rem 0 3rem;

          @media (max-width: 800px) {
            width: 70%;
            justify-content: center;
            align-items: center;
          }

          .card {
            width: clamp(10rem, 5rem + 10vw, 25rem);
            min-height: 70px;
            border-radius: 16px;
            padding: 10px 1rem;
            background: #6bbb99;
            flex-wrap: nowrap !important;
            flex-grow: 1;

            @media (max-width: 490px) {
              width: 100%;
              align-items: center;
            }

            span {
              font-weight: 600;
              font-size: 1rem;
              letter-spacing: 0.02em;
              color: #333;
              text-align: center;
              margin: 5px 0;
            }

            &:nth-child(even) {
              background: #6bbb99;
            }

            &:nth-child(odd) {
              background: #4d2456;

              span {
                color: #fff;
              }
            }

            h5 {
              font-weight: 600;
              font-size: 18px;
              letter-spacing: 0.02em;
              color: #ffffff;
              text-align: center;
              @media (max-width: 400px) {
                font-size: 16px;
                width: 90%;
              }
            }
            img {
              width: clamp(1.2rem, 1rem + 2vw, 2.5rem);
              height: clamp(1.2rem, 1rem + 2vw, 2.5rem);
              object-fit: contain;
            }

            .bodyText {
              width: 80%;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.02em;
              color: #e8edf8;
              @media (max-width: 400px) {
                width: 90%;
              }
            }
          }
        }
      }

      h2 {
        font-weight: 600;
        font-size: clamp(1.2rem, 1rem + 1.5vw, 1.8rem);
        letter-spacing: 0.03em;
        color: #2f2d51;
        width: 90%;
        text-align: center;
        margin: 30px 0 -50px 0;
      }
    }

    h2 {
      font-weight: 600;
      font-size: 24px;
      letter-spacing: 0.03em;
      color: #2f2d51;
      width: 90%;
      text-align: center;
      margin: 30px 0 -50px 0;
    }
  }
  h2 {
    font-weight: 500;
    font-size: 28px;
    line-height: 35px;
    letter-spacing: -0.065em;
    color: #2f2d51;
    @media (max-width: 800px) {
      width: 90%;
      font-size: 22px;
    }
  }

  .accessories {
    max-width: 700px;
    min-height: 200px;
    flex-direction: column;
    align-items: center;
    padding: 1rem 2rem;

    .accessories_wrapper {
      justify-content: center;
      align-items: center;
      padding: 1rem 2rem;
      text-align: center !important;
      h2 {
        text-align: center;
      }
      .accessory_item {
        justify-content: center;
        align-items: center;

        span {
          font-size: clamp(1rem, 1rem + 2vw, 1.5rem);
        }
      }
    }

    h2 {
      font-size: clamp(1.2rem, 1rem + 2vw, 2.5rem);
    }
  }
  .price_card {
    max-width: 400px;
    background-color: #6bbb99;
    border-radius: 1rem;
    padding: 1rem 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 0 0;

    h4 {
      font-size: clamp(1.1rem, 1rem + 2vw, 1.5rem);
    }
    h5 {
      font-size: clamp(1.1rem, 1rem + 2vw, 1.4rem);
      text-align: center;
    }
    span {
      font-size: clamp(1rem, 1rem + 2vw, 1.3rem);
    }

    .shop {
      margin: 2rem 0 0;
    }
  }

  .backgroundBubbles {
    position: absolute;
    z-index: -1;
    bottom: 0;
    width: 100%;
    bottom: -200px;

    @media (max-width: 800px) {
      width: 120%;
    }
    @media (max-width: 500px) {
      bottom: 40px;
    }
  }
`;
