import React from "react";
import LandingLayout from "../../components/LandingLayout";
import { AboutWrap } from "./styles";
import { Space, Typography } from "antd";
import { FlexibleDiv } from "../../components/Box/styles";
import { WhatMakesUsUniqueData } from "../../utils/dataHelpers/makesUniqueData";
import Bubbles from "../../assets/pngs/bubbleBackground.png";

const AboutUs = () => {
  const { Text, Title } = Typography;

  return (
    <LandingLayout>
      <AboutWrap>
        <FlexibleDiv>
          <FlexibleDiv
            width="800px"
            height="max-content"
            className="aboutFlex-wrap"
            flexDir="column"
          >
            <Space>
              <Title level={2}>Who We Are</Title>
            </Space>
            <Text>
            Exam Padi is Nigeria’s #1 Windows and mobile-based learning application and CBT examination preparatory and testing platform. Our platform is built to give students access to curriculum-based materials, tutorial videos and mock exams for JSCE, Senior WAEC, NECO, SSCE, JAMB, SAT etc. Exam Padi uses best-in-class teachers, media, and technology solutions to provide African students with high-quality, affordable, and open education. Students on the platform are exposed to video contents and a wide array of educational resources and past questions drawn from general exams (common entrance, JSCE, WAEC, NECO, JAMB, SAT) and this naturally gives them an edge during their preparation for these highly rated examinations.
            </Text>
            <Space>
              <Title level={2}>What Makes Us Unique</Title>
            </Space>

            {WhatMakesUsUniqueData.map((item, index) => (
              <FlexibleDiv
                flexDir="column"
                alignItems="flex-start"
                key={index}
                className="uniqueDataWrap"
              >
                <FlexibleDiv width="max-content">
                  {item.icon} <Title level={3}>{item.title}</Title>
                </FlexibleDiv>
                <Text className="uniqueDataText">{item.content}</Text>
              </FlexibleDiv>
            ))}
          </FlexibleDiv>

          <FlexibleDiv
            width="80%"
            justifyContent="space-between"
            margin="30px 0"
            className="solutionWrap"
            flexWrap="nowrap"
            alignItems="flex-start"
          >
            <Title>All - In - One Solution</Title>
            <ul>
              <li className="firstListItem">
                Very rich collection of learning resources on all subjects
              </li>
              <li className="secondListItem">
                A catalogue of past question series from the last 10 years with
                detailed solutions
              </li>
              <li className="thirdListItem">
                Interactive Online Simulated Testing feature
              </li>
              <li className="fourthListItem">
                Offline content library and testing feature
              </li>
              <li>Available in English, Hausa, Igbo, Yoruba and French</li>
            </ul>
          </FlexibleDiv>
        </FlexibleDiv>
        <img src={Bubbles} alt="" className="backgroundBubbles" />
      </AboutWrap>
    </LandingLayout>
  );
};

export default AboutUs;
