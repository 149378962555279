import Warranty from "../../assets/svgs/excellence-honor-icon.svg";
import FourG from "../../assets/svgs/4g-icon.svg"
import Android from "../../assets/svgs/android-app-icon.svg"
import AspectRatio from "../../assets/svgs/aspect-ratio-icon.svg"
import Battery from "../../assets/svgs/battery-charging-icon.svg"
import MicroSD from "../../assets/svgs/microsd-card-icon.svg"
import Ram from "../../assets/svgs/ram-memory-icon.svg"
import Rom from "../../assets/svgs/rom-read-only-memory-icon.svg"

export const specifications = [
    {
      id: 1,
      name: "9.7inch IPS Screen",
      logo: AspectRatio,
    },
    {
      id: 2,
      name: "4G Enabled",
      logo: FourG,
    },
    {
      name: "Memory card slot",
      logo: MicroSD,
    },
    {
      name: "5000mAH Battery",
      logo: Battery,
    },
    {
      name: "4GB Ram",
      logo: Ram,
    },
    {
      name: "32GB Storage",
      logo: Rom,
    },
    {
      name: "Android OS 11",
      logo: Android,
    },
    {
      name: "One Year Warranty",
      logo: Warranty
    }
  ];