import styled from "styled-components";

export const ButtonStyled = styled.button`
  border: 2px solid #6bbb99;
  display: flex;
  transition: all 0.3s ease;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  font-size: ${({ largeFont }) => (largeFont ? "18px" : "16px")};
  font-weight: ${({ largeFont }) => (largeFont ? "bold" : "500")};
  letter-spacing: 0.02857em;
  width: ${({ width }) => width || "120px"};
  padding: ${({ padding }) => padding};
  height: ${({ height }) => height || "64px"};
  background-color: ${({ outlined, bgColor }) => ( outlined ? "transparent" : bgColor ? bgColor : "#6bbb99")};
  text-transform: capitalize;
  cursor: pointer;
  outline: 0;
  margin: ${({ margin }) => margin || "0"};
  box-shadow: ${({ outlined, boxShadow }) =>
     outlined ? "none" : boxShadow ? "0px 10px 24px #E0FBF1" : "none" };

  &:active,
  &:focus {
    outline: 0;
    border: none;
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.20) 0px 5px 15px 0px;
    transform: scale(1.02);
  }



  &:disabled {
    background: gray;
    border: none;
    color: #fff;
    cursor: not-allowed;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  color: ${({ outlined, color }) => (outlined ? "#6bbb99" : color ? color : "#fff")};

  @media (max-width: 600px) {
    height: 45px;
    font-weight: 500;
  }
`;
