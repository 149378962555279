import { Typography } from 'antd';
import React from 'react'
import { AnimatedArrowWrapper, Arrow, ArrowAnimation, ArrowSliding } from './styles'


const AnimatedArrow = ({swiped}) => {
    const { Text } = Typography;

    

  return (
    <>
    <AnimatedArrowWrapper swiped={swiped}>
        <ArrowAnimation>
            <ArrowSliding className="delay1 left">
                <Arrow left/>
            </ArrowSliding>
            <ArrowSliding className="delay2 left">
                <Arrow left/>
            </ArrowSliding>
            <ArrowSliding className="delay3 left">
                <Arrow left/>
            </ArrowSliding>
        </ArrowAnimation>
        <Text>Swipe</Text>
        <ArrowAnimation className="two">
            <ArrowSliding className="delay1 right">
                <Arrow right/>
            </ArrowSliding>
            <ArrowSliding className="delay2 right">
                <Arrow right/>
            </ArrowSliding>
            <ArrowSliding className="delay3 right">
                <Arrow right/>
            </ArrowSliding>
        </ArrowAnimation>

    </AnimatedArrowWrapper>
    </>
  )
}

export default AnimatedArrow