import React, { useContext } from "react";
import LandingLayout from "../../components/LandingLayout";
import { ProductWrap, ShopWrap, ShoppingLayout } from "./styles";
import { Card, Typography } from "antd";
import { FlexibleDiv } from "../../components/Box/styles";
import Bubbles from "../../assets/pngs/bubbleBackground.png";
import { ShopItems } from "../../utils/dataHelpers/shopData";
import { useHistory } from "react-router-dom";
import { ShopContext } from "../../context/ShopProvider";

const Shop = () => {
  const { Title, Text } = Typography;
  const { Meta } = Card;
  const navigate = useHistory();
  const { setCartInfo } = useContext(ShopContext);

  const navigateToProduct = (itemId) => {
    setCartInfo((prev) => ({ ...prev, selectedProduct: itemId }));
    navigate.push(`/shop/products/${itemId}`);
  };

  return (
    <LandingLayout>
      <ShoppingLayout>
        <FlexibleDiv>
          <FlexibleDiv className="shop-area">
            <ShopWrap>
              <Title level={3}>Products</Title>
              <ProductWrap>
                {ShopItems.map((item, index) => (
                  <Card
                    key={index}
                    hoverable
                    style={{ width: 240, alignSelf: "flex-start" }}
                    cover={<img alt="example" src={item.images[4]} />}
                    onClick={() => navigateToProduct(item.id)}
                  >
                    <Meta title={item.name} description={item.desc} />
                  </Card>
                ))}
              </ProductWrap>
            </ShopWrap>
            <img src={Bubbles} alt="" className="backgroundBubbles" />
          </FlexibleDiv>
        </FlexibleDiv>
      </ShoppingLayout>
    </LandingLayout>
  );
};

export default Shop;
