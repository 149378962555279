import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import instance from "../../network/axios";
import StyledClearSession from "./styles";

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const ClearSession = () => {
  const query = useQuery();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const clearSession = async (id) => {
    setLoading(true);
    try {
      const res = await instance.post(`/auth/clear-session/${id}`);
      setMessage("Session Cleared");
    } catch (error) {
      setMessage("Error Clearing Session");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (query.get("id")) {
      clearSession(query.get("id"));
    } else {
      history.push("/");
    }
  }, [history, query]);

  return (
    <StyledClearSession>
      {loading ? <div className="loading"></div> : <div>{message}</div>}
    </StyledClearSession>
  );
};

export default ClearSession;
