import ChooseCategory from "../../assets/pngs/chooseCategory.png";
import CreateAccount from "../../assets/pngs/createAccount.png";
import AccessVideo from "../../assets/pngs/accessVideo.png";
import CardLineOne from "../../assets/pngs/cardLineOne.png";
import CardLineTwo from "../../assets/pngs/cardLineTwo.png";
import Ready from "../../assets/pngs/ready.png";
import Set from "../../assets/pngs/set.png";
import Go from "../../assets/pngs/go.png";
import ReadyImage from "../../assets/pngs/readyImage.png";
import Play from "../../assets/pngs/play.png";
import Rewind from "../../assets/pngs/playBack.png";
import Download from "../../assets/pngs/download.png";
import PlayImage from "../../assets/svgs/playImage.svg";
import BadgesImage from "../../assets/svgs/badgesImage.svg";

export const StartLearningCardsData = [
  {
    title: "Create an Exam Padi account",
    color: "#FBBE07",
    icon: CreateAccount,
    line: CardLineOne,
  },
  {
    title: "Choose a category and subscribe",
    color: "#6BBB99",
    icon: ChooseCategory,
    line: CardLineTwo,
  },

  {
    title: "Have access to video lessons and computer based tests (CBT)",
    color: "#5B64B1",
    icon: AccessVideo,
  },
];

export const PlayReadyData = [
  {
    activities: [
      {
        title: "Play.",
        image: Play,
      },
      {
        title: "Rewind.",
        image: Rewind,
      },
      {
        title: "Download.",
        image: Download,
      },
    ],
    text: "Watch videos at your own pace. Save your favourite videos to watch later and watch downloaded videos without data, whenever.",
    image: PlayImage,
  },

  {
    activities: [
      {
        title: "Ready.",
        image: Ready,
      },
      {
        title: "Set.",
        image: Set,
      },
      {
        title: "Go.",
        image: Go,
      },
    ],
    text: "Take computer based tests, in either practice or exam mode and simulate actual exam",
    image: ReadyImage,
  },

  {
    activities: [
      {
        title: "All I do is win 🥇",
      },
    ],
    text: "Unlock and earn badges as you learn. The more lessons and tests you take, the more badges you can unlock.",
    image: BadgesImage,
  },
];
