import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Radio, Typography, message } from "antd";
import { ShopItems } from "../../../utils/dataHelpers/shopData";
import LandingLayout from "../../../components/LandingLayout";
import { ShopWrap, ShoppingLayout } from "../styles";
import { FlexibleDiv } from "../../../components/Box/styles";
import Bubbles from "../../../assets/pngs/bubbleBackground.png";
import { ShopContext } from "../../../context/ShopProvider";
import ProductSlider from "./ProductSlider";
import VisaCard from "../../../assets/pngs/visa.png";
import Verve from "../../../assets/pngs/verve.png";
import MasterCard from "../../../assets/pngs/mastercard.png";
import DescriptionNReview from "./DescriptionNReview";
import RelatedProducts from "./RelatedProducts";
import { useHistory } from "react-router-dom";

const styles = {
  mainProductViewWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "column",
    gap: "2rem",
  },
  mainProductViewInner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    gap: "2rem",
    width: "100%",
  },
  imageSection: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    maxWidth: "50%",
    width: "100%",
    height: "100%",
  },

  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  descSection: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    width: "50%",
  },
  descTitle: {
    fontSize: "1.3rem",
    lineheight: "1",
    margin: "0",
  },
  descSubtitle: {
    fontSize: "1rem",
    lineheight: "1",
    margin: "0",
  },
  descListWrapper: {
    padding: "0 2rem",
  },
  descList: {
    listStyleType: "disc",
    lineHeight: 2,
  },
};

const MainProductView = ({ match }) => {
  const { Text, Title } = Typography;
  const { cartInfo, setCartInfo, isLoading, currencyFormatter } =
    useContext(ShopContext);
  const [selectedItem, setSelecteditem] = useState({});
  const [quantity, setQuantity] = useState( cartInfo.items.find((item) => item.id === cartInfo.selectedProduct)?.quantity || 1);
  const navigate = useHistory();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (match.params.productId === cartInfo.selectedProduct) {
      const selected = ShopItems.find(
        (item) => item.id === cartInfo.selectedProduct
      );
      setSelecteditem(selected);
    }
  }, [cartInfo, match]);

  const addToCart = useCallback(() => {
    let temp = [...cartInfo.items];
    const isExists = temp.some((item) => item.id === selectedItem.id);
    if (!isExists) {
      temp.push({
        quantity,
        totalAmount: selectedItem.price * quantity,
        id: selectedItem.id,
        name: selectedItem.name,
        img: selectedItem.images[0],
        price: selectedItem.price,
      });
      setCartInfo((prev) => ({ ...prev, items: temp, quantity }));
    } else {
      temp = temp.map((item) =>
        item.id === selectedItem.id
          ? {
              ...item,
              quantity,
              totalAmount: selectedItem.price * quantity,
            }
          : item
      );
      setCartInfo((prev) => ({ ...prev, items: temp, quantity }));
    }

    navigate.push(`/shop/cart`);
  }, [cartInfo, selectedItem, navigate, quantity, setCartInfo]);

  const add = () => {
    setQuantity((prev) => prev + 1);
  };
  const sub = () => {
    setQuantity((prev) => (prev <= 0 ? 0 : prev - 1));
  };

  return (
    <LandingLayout>
      {contextHolder}
      <ShoppingLayout>
        <FlexibleDiv>
          <FlexibleDiv className="shop-area">
            <ShopWrap>
              {isLoading ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "10",
                  }}
                >
                  <Text>Loading</Text>
                </div>
              ) : (
                <div style={styles.mainProductViewWrapper}>
                  {Object.entries(selectedItem).length > 0 ? (
                    <>
                      <div style={styles.mainProductViewInner} className="mainProductViewInner">
                        <div style={styles.imageSection} className="imageSection">
                          <ProductSlider images={selectedItem.images} />
                        </div>
                        <div style={styles.descSection} className="descSection">
                          <Title level={3} style={styles.descTitle}>
                            {selectedItem.name}
                          </Title>
                          <Title level={5}>{selectedItem.title}</Title>
                          <div style={styles.descListWrapper}>
                            <ul style={styles.descList}>
                              {selectedItem.perks.map((perk, index) => (
                                <li key={index} style={styles.descList}>
                                  {perk}
                                </li>
                              ))}
                            </ul>
                          </div>
                          <Title style={{ fontSize: "1.3rem" }} level={3}>
                            {currencyFormatter.format(selectedItem.price)}
                          </Title>
                          <div className="cart-settings">
                            <div className="add-to-cart-button-wrapper">
                              <Button onClick={sub}>-</Button>
                              <Text className="item-count">{quantity}</Text>
                              <Button onClick={add}>+</Button>
                            </div>
                            <Button onClick={addToCart}>Add to Cart</Button>
                          </div>

                          <div className="credit-cards">
                            <form>
                              <fieldset>
                                <legend>Guaranteed Safe Checkout</legend>
                                <img src={VisaCard} alt="" />
                                <img
                                  src={MasterCard}
                                  alt=""
                                  style={{ height: "30px" }}
                                  height={50}
                                />
                                <img
                                  src={Verve}
                                  alt=""
                                  style={{ height: "30px" }}
                                  height={50}
                                />
                              </fieldset>
                            </form>
                          </div>
                        </div>
                      </div>
                      <DescriptionNReview selectedItem={selectedItem} />
                    </>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "10",
                      }}
                    >
                      <Text>Cart is empty</Text>
                    </div>
                  )}
                </div>
              )}
              <RelatedProducts selectedItem={selectedItem} />
            </ShopWrap>
            <img src={Bubbles} alt="" className="backgroundBubbles" />
          </FlexibleDiv>
        </FlexibleDiv>
      </ShoppingLayout>
    </LandingLayout>
  );
};

export default MainProductView;
