import styled from "styled-components";
import BackgroundImage from "../../../assets/pngs/heroSectionBackground.png";

export const HeroSectionWrap = styled.div`
  width: 100%;
  min-height: 100vh;
  height: max-content;
  background-image: url(${BackgroundImage});
  background-size: 100% 100%;
  background-repeat: no-repeat;

  @media (max-width: 800px) {
    padding-top: 150px;
    background-size: cover;
    background-position: center;
  }

  .heroSection {
    .heroSection__leftDiv {
      @media (max-width: 980px) {
        width: max-content;
        margin: 0 30px;
      }
      @media (max-width: 800px) {
        width: 100%;
        justify-content: center;
        height: max-content;
        margin: 30px 0;
      }

      .heroSection__leftDiv__sub {
        position: relative;
        margin-bottom: -100px;
        @media (max-width: 800px) {
          align-items: center;
        }
        @media (max-width: 450px) {
          width: 90%;
        }
        .heroSection__leftDiv__rocketImage {
          position: absolute;
          left: -40px;
          width: 150%;
          height: auto;
          z-index: -1;

          @media (max-width: 850px) {
            width: 130%;
          }
          @media (max-width: 450px) {
            width: 400px;
            left: 3px;
          }
          @media (max-width: 400px) {
            width: 115%;
            left: -15px;
          }
        }
        h1 {
          font-family: "Permanent Marker", cursive;
          font-weight: 400;
          font-size: 50px;
          text-transform: capitalize;
          letter-spacing: -0.04em;
          line-height: 50px;
          max-width: 420px;

          font {
            font-family: "Permanent Marker", cursive;
          }
          @media (max-width: 800px) {
            text-align: center;
          }
          @media (max-width: 450px) {
            font-size: 40px;
            width: 100%;
          }
          span {
            font-family: "Permanent Marker", cursive;
          }
        }
        .heroSection__leftDiv__smallText {
          margin-bottom: 20px;
          width: 350px;
          font-size: 16px;
          line-height: 25px;
          letter-spacing: 0.03em;
          font-weight: 600;
          color: #828282;
          @media (max-width: 800px) {
            text-align: center;
          }
          @media (max-width: 450px) {
            width: 100%;
          }
        }
      }
    }

    .heroSection__rightDiv {
      width: 40%;
      max-width: 40%;
      height: auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .studyImageMobile {
        display: none;
      }
      @media (max-width: 800px) {
        width: 80%;
        max-width: 80%;
        margin: 40px 0 20px 10%;
        .studyImageMobile {
          display: block;
        }
        .studyImageDesktop {
          display: none;
        }
      }
    }
  }
`;
