import styled from "styled-components";

export const FaqWrap = styled.div`
  width: 100%;
  min-height: 80vh;

  .largeHeader {
    height: 300px;
    margin-top: 100px;
    background: #4d2456;
    position: relative;
    @media (max-width: 800px) {
      margin-top: 80px;
    }
    @media (max-width: 600px) {
      height: 200px;
    }

    h1 {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 150px;
      letter-spacing: 0.03em;
      color: #c4c4c4;
      opacity: 0.3;
      margin: 0;
      -webkit-text-stroke: 3px #fefefe;

      font {
        font-family: Montserrat;
      }
      @media (max-width: 900px) {
        font-size: 120px;
      }
      @media (max-width: 800px) {
        font-size: 100px;
      }
      @media (max-width: 600px) {
        font-size: 70px;
      }
      @media (max-width: 400px) {
        text-align: center;
      }
    }
    h3 {
      font-weight: bold;
      font-size: 46px;
      letter-spacing: 0.03em;
      color: #ffffff;
      position: absolute;
      left: 8%;
      bottom: 15%;

      @media (max-width: 1000px) {
        left: 3%;
      }
      @media (max-width: 800px) {
        font-size: 30px;
        bottom: 45%;
      }
      @media (max-width: 600px) {
        font-size: 20px;
      }
    }

    img {
      @media (max-width: 1100px) {
        width: 100%;
      }
      @media (max-width: 1100px) {
        width: 90%;
      }
      @media (max-width: 800px) {
        width: 60%;
      }
    }
  }

  .faqMainWrap {
    padding: 50px 0 100px 0;
    @media (max-width: 800px) {
      justify-content: center;
    }
    @media (max-width: 400px) {
      padding-top: 50px;
    }

    .faqContentWrap {
      margin-left: 50px;
      @media (max-width: 1200px) {
        width: 40%;
      }
      @media (max-width: 850px) {
        width: 49%;
      }
      @media (max-width: 800px) {
        margin-left: 0;
        width: 90%;
      }
      @media (max-width: 450px) {
        margin-top: 0;
      }
    }
  }
`;
