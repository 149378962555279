import React from "react";
import { FlexibleDiv } from "../../../components/Box/styles";
import { AboutSectionWrap } from "./styles";
import { Typography } from "antd";
import AboutSecionImage from "../../../assets/pngs/aboutSectionPic.png";

const AboutSection = () => {
  const { Text, Title } = Typography;

  return (
    <AboutSectionWrap>
      <FlexibleDiv className="aboutSectionParentWrap" flexDir="column">
        <FlexibleDiv className="aboutSectionImageWrap">
          <img src={AboutSecionImage} alt="" />
        </FlexibleDiv>
        <FlexibleDiv
          className="aboutSectionTextWrap"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Title>What is Exam Padi</Title>

          <Text className="aboutTextSmall" type="secondary">
            Exam Padi is the companion you need to prepare for your entrance and
            school leaving examinations. <br /> <br />
            It is an e-learning platform hosting learning videos and computer
            based tests to help JS 3 and SS 3 students (exit class) prepare for
            Junior and Senior WAEC, NECO, JAMB and SAT.
            <br /> <br /> It also caters to primary school students that will be
            writing the Common Entrance examination, providing video lessons and
            computer based tests.
          </Text>
        </FlexibleDiv>
      </FlexibleDiv>
    </AboutSectionWrap>
  );
};

export default AboutSection;
