import styled from "styled-components";
import BackgroundImage from "../../assets/pngs/heroSectionBackground.png";

export const ShopWrap = styled.div`
  width: min(100% - 100px, 1250px);
  background-color: #ffffff;
  height: 100%;
  margin: 120px 0 2.5rem;
  position: relative;
  padding: 3rem 2rem;
  border-radius: 5px;
  z-index: 10;

  .mainProductViewInner{
    
    @media (max-width: 850px) {
        flex-direction: column;

        .imageSection, .descSection{
            max-width: 100% !important;
            width: 100% !important;
        }

    
        .OrderSummaryWrapper {
          width: 100%;
        }
      }
  }

  .product-image-slider {
    width: 100%;
    height: 100%;
    .swiper-slide {
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .swiper-button-prev {
      left: 30;
      color: rgb(255, 255, 255);
    }
    .swiper-button-next {
      right: 30;
      color: rgb(255, 255, 255);
    }
  }

  .product-image-slider-thumbs {
    width: 100%;
    height: 100%;
    .swiper-slide {
      cursor: pointer;
      border: 1px solid #dddbdb;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &-thumb-active {
        border-color: #2b0434;
      }

      &-wrapper {
        width: 100%;
        padding-top: 65%;
        overflow: hidden;
        position: relative;
      }
    }
  }

  .cart-settings {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;

    .add-to-cart-button-wrapper {
      display: flex;
      justify-content: stretch;
      align-items: center;

      .item-count {
        display: inline-flex;
        border: 1px solid transparent;
        border-top: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;
      }

      button,
      .item-count {
        padding: 4px 10px !important;
        border-radius: 0;
      }

      button:hover,
      button:focus,
      button:active {
        border-color: #2b0434 !important;
        color: #2b0434 !important;
      }
    }

    button:hover,
    button:focus,
    button:active {
      border-color: #2b0434;
      color: #2b0434;
    }
  }

  .credit-cards {
    margin-top: 1rem;
    fieldset {
      border: 1px solid gray;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0 0.5rem;
      gap: 1rem;
      legend {
        width: unset;
        color: gray;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        padding: 0 8px;
        margin-bottom: 0;
        font-size: 1em;
        font-weight: 600;
        text-align: center;
      }
      img {
        height: 20px;
      }
    }
  }

  .ant-tabs {
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #2b0434;
      }
    }

    .ant-tabs-tab-btn {
      &:hover {
        color: #2b0434;
      }
    }

    .ant-tabs-ink-bar {
      background: #2b0434;
    }
  }

  .related-products {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &.cart {
    .ant-table-wrapper {
      overflow-x: scroll;
    }
    table {
      tr {
        td {
          .close {
            display: flex;
            font-size: 1rem;
            justifycontent: center;
            alignitems: center;
            cursor: pointer;

            &:hover {
              color: #e03838;
            }
          }
        }
      }
    }

    .order-details {
      display: flex;
      justify-content: flex-end;
      margin: 2rem 0 0;
      .ant-card {
        padding: 0;
        border-width: 1px;
        border-style: solid;
        border-color: #d7d7d7;

        .ant-card-head {
          background-color: #fbfbfb;
          font-weight: 600;
        }

        .cart-item {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .cart-item-title,
          .cart-item-value {
            width: 50%;
          }

          span strong {
            cursor: pointer;
          }

          .ant-collapse {
            border: none;
            background-color: transparent;

            .ant-collapse-header {
              padding: 0;
              background-color: transparent;
              border: none;
              font-weight: 600;
            }

            .ant-collapse-content {
              border: none;

              .ant-collapse-content-box {
                padding: 1rem 0;

                .ant-form-horizontal .ant-form-item-control {
                  max-width: 100%;
                  margin: 0;
                }
              }

              .ant-col ant-form-item-control {
                margin: 0;
              }
              button {
                width: 100%;
                background-color: transparent;
                color: #2b0434;
                border-color: #2b0434;
                font-weight: 600;
                font-size: 1rem;

                &:hover {
                  background-color: #2b0434;
                  color: #fff;
                }
              }
            }

            .ant-collapse-item {
              border-bottom: 0;
              font-weight: 600;
              font-size: 1rem;
            }
          }
        }

        .checkout-btn {
          width: 100%;
          background-color: #2b0434;
          border: 1px solid #2b0434;
          color: #fff;
          ourline: 0;

          &:hover {
            background-color: transparent;
            color: #2b0434;
          }
        }
      }
    }
  }
`;

export const ShoppingLayout = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundImage});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 998;

  .shop-area {
    position: relative;
    padding: 2rem 0;
  }

  .backgroundBubbles {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .related-products {
  }
`;

export const ProductWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  flex-wrap: wrap;
`;

export const CheckoutWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  button {
    margin: 0;
    position: relative;
    width: 100%;
    border: 1px solid #2b0434 !important;
    margin: 1.5rem 0 0;
    height: 32px;
    background-color: transparent;

    span {
      color: #2b0434;
    }

    &:hover,
    &:focus {
      color: #ffffff;
      background-color: #2b0434;

      span {
        color: #ffffff;
      }
    }
  }

  @media (max-width: 850px) {
    flex-direction: column;

    .OrderSummaryWrapper {
      width: 100%;
    }
  }
`;

export const OrderFormWrapper = styled.div`
  flex: 1;
  flex-basis: 60%;
`;

export const OrderSummaryWrapper = styled.div`
  flex: 1;
  width: 50%;

  .ant-card {
    .ant-card-head {
      .order-summary-header {
        display: flex;
        flex-direction: column;
        width: 100%;

        .order-summary-title {
          display: flex;
          justify-content: space-between;
        }

        h4 {
          color: #4b4f58;
          font-weight: 700;
          font-size: 1rem;
        }
      }
    }

    .order-summary {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 1rem;
      gap: 1rem;

      .order-summary-item {
        font-size: 0.9rem;
        font-weight: 600;
      }

      .order-summary-item.value {
        text-align: left;
      }
    }

    .credit-cards {
      display: flex;
      flex-direction: column;
      margin: 1rem;
      max-width: 400px;
      width: 100%;

      .paystack-image {
        display: flex;
        flex-direction: column;
        position: relative;

        span {
          background-color: #efefef;
          padding: 0.2rem;
          margin-top: 1rem;
          position: relative;

          &::before {
            content: "";
            display: block;
            border: 1em solid #dfdcde;
            border-right-color: transparent;
            border-left-color: transparent;
            border-top-color: transparent;
            position: absolute;
            top: -1em;
            left: -28px;
            margin: -1em 0 0 2em;
          }
        }
      }
    }

    .foot-note {
      border: 0;
      display: inline-block;
      font-size: 1em;
      font-weight: inherit;
      font-style: inherit;
      margin: 0;
      outline: 0;
      padding: 0;
      vertical-align: baseline;
      color: rgba(0, 0, 0, 0.85) a {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.85) !important;
      }
    }

    button {
      margin: 0;
      position: relative;
      width: 100%;
      border: 1px solid #2b0434 !important;
      margin: 1.5rem 0 0;
      height: 32px;
      background-color: transparent;

      span {
        color: #2b0434;
      }

      &:hover,
      &:focus {
        color: #ffffff;
        background-color: #2b0434;

        span {
          color: #ffffff;
        }
      }
    }
  }
`;
