import React from "react";
import { FlexibleDiv } from "../../../components/Box/styles";
import { WinSectionWrap } from "./styles";
import TakeLessonsWithYou from "../../../components/TakeLessonsWithYou";

const WinSection = () => {
  return (
    <WinSectionWrap>
      <FlexibleDiv id="download">
        <TakeLessonsWithYou id="download" />
      </FlexibleDiv>
    </WinSectionWrap>
  );
};

export default WinSection;
