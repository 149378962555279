import React from "react";
import { ButtonStyled } from "./styles";
import Loader from "../Loaders";

const Button = React.forwardRef(
  (
    {
      type,
      text,
      loading,
      disabled,
      click,
      icon,
      outlined,
      width,
      height,
      largeFont,
      margin,
      padding,
      bgColor,
      color,
      boxShadow,
      ...otherProps
    },
    ref
  ) => {
    return (
      <ButtonStyled
        ref={ref}
        type={type}
        onClick={click}
        disabled={disabled}
        width={width}
        height={height}
        largeFont={largeFont}
        outlined={outlined}
        margin={margin}
        padding={padding}
        boxShadow={boxShadow}
        bgColor={bgColor}
        color={color}
        {...otherProps}
      >
        {loading ? <Loader size={"sm"} {...otherProps} /> : text}
      </ButtonStyled>
    );
  }
);

export default Button;
