import React from "react";
import { FlexibleDiv } from "../../../components/Box/styles";
import { StartLearningSectionWrap } from "./styles";
import { Typography } from "antd";
import {
  StartLearningCardsData,
  PlayReadyData,
} from "../../../utils/dataHelpers/startLearningData";

const StartLearningSection = () => {
  const { Text, Title } = Typography;

  return (
    <StartLearningSectionWrap>
      <FlexibleDiv
        flexDir="column"
        className="startLearningSection"
        margin="0 0 40px 0"
      >
        {/* how to start learning card */}
        <FlexibleDiv width="70%" className="startLearningSection__card">
          <Title>How To Start Learning</Title>
          <FlexibleDiv
            className="startLearningSection__cardBodyWrap"
            justifyContent="space-between"
            width="90%"
            height="max-content"
            flexWrap="noWrap"
          >
            {StartLearningCardsData.map((item, index) => (
              <FlexibleDiv
                key={index}
                width="max-content"
                flexDir="column"
                alignItems="flex-start"
                className="startLearningSection__cardBodyMain"
              >
                <FlexibleDiv
                  justifyContent="flex-start"
                  className="startLearningSection__cardBodyTopWrap"
                >
                  <FlexibleDiv
                    style={{ backgroundColor: item.color }}
                    className="startLearningSection__cardBodyImageWrap"
                    width="50px"
                    height="50px"
                    margin="0 20px 0 0"
                  >
                    <img alt="" src={item.icon} />
                  </FlexibleDiv>
                  <img
                    alt=""
                    src={item.line}
                    style={{ width: "60%" }}
                    className="svgLine"
                  />
                </FlexibleDiv>
                <Text className="startLearningSection__cardBodyText">
                  {item.title}
                </Text>
              </FlexibleDiv>
            ))}
          </FlexibleDiv>
        </FlexibleDiv>

        {/* Ready Play Section */}
        <FlexibleDiv
          flexDir="column"
          className="startLearningSection__readyPlay"
          width="700px"
          margin="20px 0"
          flexWrap="nowrap"
        >
          {PlayReadyData.map((item, index) => (
            <FlexibleDiv
              key={index}
              justifyContent="space-between"
              className="startLearningSection__readyPlayBody"
              flexDir={index === 1 ? "row-reverse" : "row"}
              margin="100px 0 0 0"
            >
              <FlexibleDiv
                className="startLearningSection__readyPlayBodyImageWrap"
                style={{ maxWidth: index === 0 && "85%" }}
              >
                <img src={item.image} alt="" />
              </FlexibleDiv>

              <FlexibleDiv
                width="350px"
                height="max-content"
                alignItems="flex-start"
                flexDir="column"
                className="startLearningSection__readyPlayBodyContentWrap"
              >
                {item.activities.map((activity, idx) => (
                  <FlexibleDiv
                    justifyContent="flex-start"
                    className="activityWrap"
                    style={{ minWidth: index === 0 && "100%" }}
                  >
                    <Title>{activity.title}</Title>
                    {!!activity.image && (
                      <div className="activityImage">
                        <img alt="" src={activity.image} />
                      </div>
                    )}
                  </FlexibleDiv>
                ))}
                <Text className="bodyText">{item.text}</Text>
              </FlexibleDiv>
            </FlexibleDiv>
          ))}
        </FlexibleDiv>
      </FlexibleDiv>
    </StartLearningSectionWrap>
  );
};

export default StartLearningSection;
