import React from "react";
import { BiPlus as Plus, BiMinus as Minus } from "react-icons/bi";

export const FaqData = [
  {
    title: "Is exam padi free?",
    subTitle:
      "No, it is not. Exam Padi is a subscription-based e-learning platform with learning videos and computer-based tests to help Primary 6, JS 3 and SS 3 students (exit classes) prepare for common entrance, Junior and Senior WAEC, NECO, JAMB and SAT examinations.",
    plus: <Plus />,
    minus: <Minus />,
  },
  {
    title: "What category of subscription are available for me?",
    subTitle:
      "Exam Padi has four main subscription categories namely, common entrance, Junior Secondary, Senior Secondary and full subscription (which covers all other categories). Users can pay for monthly, quarterly, or yearly plans based on their discretion.",
    plus: <Plus />,
    minus: <Minus />,
  },
  {
    title: "How do I sign up?",
    subTitle:
      "All you need to do is to download the Exam Padi app from the google playstore (for android users) or visit exampadi.ng (for the web app) to sign up. After signing up by creating an account. Navigate to the any of the subscription category of your choice. Subscribe and start learning on the go.",
    plus: <Plus />,
    minus: <Minus />,
  },
  {
    title: "How much do the plans cost?",
    subTitle:
      "Users on Exam Padi have the option of subscribing to any of the four categories, either monthly, quarterly, or yearly based on their discretion.",
    plus: <Plus />,
    minus: <Minus />,
    link: "/subscription",
  },

  {
    title: "What should I expect using exam padi?",
    subTitle: `The educational resources on the app have been tailored to give students in exit classes a unique advantage guaranteed to expose them to NERDC approved contents prepared by world class subject knowledge experts in the different subjects of focus.`,
    plus: <Plus />,
    minus: <Minus />,
  },
  {
    title: "Is exam padi available on all platforms?",
    subTitle:
      "No. For now, Exam Padi is only available on android and windows devices.",
    plus: <Plus />,
    minus: <Minus />,
  },
  {
    title: "What is your refund policy?",
    subTitle:
      "Users of Exam Padi are entitled to refunds. However, refund requests are open within a 7-day window. On the expiry of the window, such a request would not be processed. To initiate a refund, please send a mail to info@exampadi.ng within 7 days of subscription with proof and details to enable us to process your request. Terms and conditions apply.",
    plus: <Plus />,
    minus: <Minus />,
  },
];
