import React, { useEffect, useState } from "react";

const RedirectToSKMShop = () => {
  const [counter, setCounter] = useState(3);

  useEffect(() => {
    if (counter === 0)
      window.location.href =
        "https://skoolmedia.ng/product/exam-padi-education-tablet/";
  }, [counter]);

  useEffect(() => {
    setInterval(() => {
      setCounter((prev) => (prev > 0 ? prev - 1 : 0));
    }, [1000]);
  }, []);

  return (
    <div>
      {counter !== 0 ? (
        <p>Redirecting ({counter})...</p>
      ) : (
        <p>
          If you are not redirected click here{" "}
          <a
            href="https://skoolmedia.ng/product/exam-padi-education-tablet/"
            rel="noopener noreferrer"
          >
            click here
          </a>
        </p>
      )}
    </div>
  );
};

export default RedirectToSKMShop;
