import { FlexibleDiv } from "../../components/Box/styles";
import LandingLayout from "../../components/LandingLayout";
import {
  CheckoutWrapper,
  OrderFormWrapper,
  OrderSummaryWrapper,
  ShopWrap,
  ShoppingLayout,
} from "./styles";
import Bubbles from "../../assets/pngs/bubbleBackground.png";
import Paystack from "../../assets/pngs/paystack-wc.png";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Radio,
  Select,
  Typography,
} from "antd";
import { useContext, useState } from "react";
import countriesList from "../../utils/dataHelpers/countries_n_states";
import { ShopContext } from "../../context/ShopProvider";

const Checkout = () => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("vertical");
  const {
    cartInfo: {
      quantity,
      totalAmount,
      billingAddress,
      selectedProduct,
      ...cartInfo
    },
    setCartInfo,
    currencyFormatter,
  } = useContext(ShopContext);


  const { Text } = Typography;
  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const onFinish = (value) => {
    console.log("search:", value);
    setCartInfo((prev) => ({ ...prev, billingAddress: value }));
  };

  return (
    <LandingLayout>
      <ShoppingLayout>
        <FlexibleDiv>
          <FlexibleDiv className="shop-area">
            <ShopWrap>
              <CheckoutWrapper>
                <OrderFormWrapper>
                  <Form
                    layout="vertical"
                    form={form}
                    title="Billing Details"
                    name="Billing Details"
                    onValuesChange={onFormLayoutChange}
                    onFinish={onFinish}
                  >
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Form.Item
                        label="Firstname"
                        name="firstname"
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 12px)",
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Please input your firstname!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <span
                        style={{
                          display: "inline-block",
                          width: "24px",
                          lineHeight: "32px",
                          textAlign: "center",
                        }}
                      ></span>

                      <Form.Item
                        label="Lastname"
                        name="lastname"
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 12px)",
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Please input your lastname!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label="Company Name (Optional)"
                      name="company_name"
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="country"
                      label="Country / Region"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please select your country!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select a person"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={countriesList}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Street address"
                      name="street"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your street address!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Town / City"
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your town/city!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="State / County"
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your state/county!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Postcode / ZIP"
                      name="postcode"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your postcode/zip!",
                        },
                      ]}
                    >
                      <Input type="number" />
                    </Form.Item>

                    <Form.Item
                      label="Phone"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your phone!",
                        },
                      ]}
                    >
                      <Input type="phone" />
                    </Form.Item>

                    <Form.Item
                      label="Email address"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your email!",
                        },
                      ]}
                    >
                      <Input type="email" />
                    </Form.Item>

                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </OrderFormWrapper>
                <OrderSummaryWrapper className="OrderSummaryWrapper">
                  <Card
                    title={
                      <div className="order-summary-header">
                        <h4>Your Order</h4>
                        {/* <div className="order-summary-title">
                          <h4>Product</h4>
                          <h4>Subtotal</h4>
                        </div> */}
                      </div>
                    }
                  >
                    {cartInfo.items.map((item) => (
                      <div className="order-summary">
                        <div className="order-summary-item title">
                          {item.name} x {item.quantity}
                        </div>
                        <div className="order-summary-item value">
                          {item.totalAmount}
                        </div>
                      </div>
                    ))}
                    <Divider />
                    <div className="order-summary">
                      <div className="order-summary-item title">Shipping</div>
                      <div className="order-summary-item value">
                        Enter your address to view shipping options
                      </div>
                    </div>
                    <Divider />

                    <div className="order-summary">
                      <div className="order-summary-item title">Subtotal</div>
                      <div className="order-summary-item value">
                        {cartInfo.totalAmount}
                      </div>
                    </div>

                    <Divider />
                    <div className="order-summary">
                      <div className="order-summary-item title">Total</div>
                      <div className="order-summary-item value">
                        {cartInfo.totalAmount}
                      </div>
                    </div>

                    <div className="credit-cards">
                      <Text>Debit/Credit Cards</Text>

                      <div className="paystack-image">
                        <img src={Paystack} alt="" width={300} />
                        <span>
                          Make payment using your debit and credit cards
                        </span>
                      </div>
                    </div>

                    <Divider />
                    <Text className="foot-note">
                      Your personal data will be used to process your order,
                      support your experience throughout this website, and for
                      other purposes described in our{" "}
                      <a
                        href="https://exampadi.ng/privacy-policy"
                        without
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {" "}
                        privacy policy.
                      </a>{" "}
                    </Text>

                    <Button
                      onClick={() => {
                        window.location.href = 'https://paystack.com/buy/exampadi-tab-iocvdg';
                      }}
                    >
                      Place Order
                    </Button>
                  </Card>
                </OrderSummaryWrapper>
              </CheckoutWrapper>
            </ShopWrap>
            <img src={Bubbles} alt="" className="backgroundBubbles" />
          </FlexibleDiv>
        </FlexibleDiv>
      </ShoppingLayout>
    </LandingLayout>
  );
};

export default Checkout;
