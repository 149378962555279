import styled from "styled-components";

export const StudentsFeedbackSectionWrap = styled.div`
  width: 100%;
  height: max-content;
  padding: 120px 0 0 0;
  @media (max-width: 800px) {
    padding: 60px 0;
  }

  .ant-carousel .slick-dots li.slick-active button {
    background-color: gray !important;
  }
  button {
    height: 15px !important;
    width: 15px !important;
    border-radius: 100% !important;
    border: 2px solid gray !important;
    @media (max-width: 330px) {
      margin-top: 50px !important;
    }
  }
  h1 {
    margin-bottom: 60px;
    text-align: center;
    font-size: 35px;
    @media (max-width: 800px) {
      font-size: 24px;
    }
  }

  small {
    font-style: italic;
  }

  .carouselWrap {
    width: 100vw !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: max-content;
  }
  .carouselMainWrap {
    flex-wrap: nowrap !important;
    width: 950px !important;
    height: max-content !important;
    display: flex !important;
    justify-content: space-between;
    flex-wrap: wrap !important;
    @media (max-width: 1000px) {
      width: 780px !important;
    }
    @media (max-width: 800px) {
      width: 780px !important;
      justify-content: center;
      img {
        width: 70px !important;
        height: 70px !important;
      }
    }

    img {
      width: 100px;
      height: 100px;
      margin-right: 10px;
    }
    p {
      font-weight: 600;
    }

    .carouselContet {
      @media (max-width: 1000px) {
        width: 350px !important;
      }
      @media (max-width: 800px) {
        width: 90% !important;
      }
    }
  }

  .desktopCarousel {
    @media (max-width: 800px) {
      display: none;
    }
  }

  .mobileCarousel {
    @media (min-width: 800px) {
      display: none;
    }

    .mobileCarouselMianWrap {
      display: flex !important;
      width: 100% !important;
      justify-content: center !important;
    }
    .mobileCarouselContet {
      display: flex !important;
      align-items: flex-start;
      flex-wrap: nowrap;
      width: 90% !important;
      justify-content: center !important;

      img {
        width: 70px !important;
        height: 70px !important;
        margin-right: 10px;
      }

      p {
        width: 70%;
        font-weight: 600;
      }
    }
  }

  .name {
    display: flex !important;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const cotent = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  height: "200px",
  width: "450px",
  alignItems: "flex-start",
  textAlign: "start",
  marginBottom: "30px",
};
