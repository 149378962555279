import React from "react";
import LandingLayout from "../../components/LandingLayout";
import { FlexibleDiv } from "../../components/Box/styles";
import { ContactWrap } from "./styles";
import Button from "../../components/Button";
import { Typography } from "antd";

const ContactUs = () => {
  const { Title } = Typography;
  return (
    <LandingLayout>
      <ContactWrap>
        <FlexibleDiv flexDir="column">
          <FlexibleDiv className="largeHeader">
            {/* <FlexibleDiv width="80%" justifyContent="flex-start"> */}
            <Title>contact us</Title>
            <Title level={3}>Contact Us</Title>
            {/* </FlexibleDiv> */}
          </FlexibleDiv>
          <FlexibleDiv
            width="600px"
            height="max-content"
            flexDir="column"
            className="formWrap"
          >
            <form>
              <label for="name">Name</label>
              <input type="text" placeholder="Name" />
              <label for="email">Email</label>
              <input type="text" placeholder="Email" />
              <label for="message">Message</label>
              <textarea type="text" placeholder="Send Us A Message"></textarea>
              <FlexibleDiv
                className="buttonWrap"
                justifyContent="flex-start"
                margin="20px 0 0 0"
              >
                <Button
                  text="Send Message"
                  height="55px"
                  largeFont
                  width="max-content"
                  padding="0 20px"
                />
              </FlexibleDiv>
            </form>
          </FlexibleDiv>
        </FlexibleDiv>
      </ContactWrap>
    </LandingLayout>
  );
};

export default ContactUs;
