import styled from "styled-components";

export const AboutWrap = styled.div`
  width: 100%;
  padding: 150px 0 150px 0;
  position: relative;

  @media (max-width: 800px) {
    padding: 100px 0 150px 0;
  }

  .aboutFlex-wrap {
    @media (max-width: 800px) {
      width: 90%;
    }

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      color: #4d2456;
      margin: 50px 0 20px 0;
    }

    .uniqueDataWrap {
      margin-bottom: 30px;
      h3 {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #4f4f4f;
        margin: 7px 0 0 20px;

        @media (max-width: 800px) {
          font-size: 16px;
        }
      }

      .uniqueDataText {
        margin: 15px 0 0 15px;
      }
    }
  }

  .solutionWrap {
    @media (max-width: 1100px) {
      width: 90%;
    }
    @media (max-width: 800px) {
      flex-direction: column;
    }
    h1 {
      font-weight: bold;
      font-size: 64px;
      line-height: 78px;
      letter-spacing: 0.02em;
      color: rgba(77, 36, 86, 0.9);
      min-width: 450px;
      text-align: left;
      @media (max-width: 800px) {
        min-width: 100%;
        width: 100%;
        font-size: 30px;
        line-height: 40px;
      }
    }

    ul {
      li {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 20px;
        list-style-type: disc;
        /* list-style= */
        line-height: 25px;
        letter-spacing: 0.02em;
        color: #4f4f4f;
        transition: all 0.3s ease;

        @media (max-width: 900px) {
          width: 100%;
          margin: 0 0 20px 10px !important;
        }
      }
      .firstListItem {
        margin-left: 75px;
      }
      .secondListItem {
        margin-left: 55px;
      }
      .thirdListItem {
        margin-left: 30px;
      }
      .fourthListItem {
        margin-left: 15px;
      }

      @media (max-width: 800px) {
        width: 100%;

        li {
          width: 100%;
          font-size: 15px;
        }
      }
    }
  }

  .backgroundBubbles {
    position: absolute;
    z-index: -1;
    bottom: 0;
    width: 100%;
    bottom: -80px;
    opacity: 0.5;

    @media (max-width: 800px) {
      width: 120%;
    }
    @media (max-width: 500px) {
      bottom: 40px;
    }
  }
`;
