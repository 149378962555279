import React, { useEffect, useState } from "react";
import { FlexibleDiv } from "../../components/Box/styles";
import { WemaVerificationWrap } from "./styles";
import { Typography, Alert } from "antd";
import { useLocation } from "react-router-dom";
import instance from "../../network/axios";
import LandingLayout from "../../components/LandingLayout";
import Button from "../../components/Button";
import jwtDecode from "jwt-decode";

const EmailVerificationPage = () => {
  const { Title } = Typography;
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const [couponCode, setCouponCode] = useState("");
  // I dont see anywhere this is used, im commenting it out to test netlify building
  // const [_email, setEmail] = useState("");
  const [payload, setPayload] = useState({
    sub_code: "",
    email: "",
    device_id: "",
    device_name: "",
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [inValidTokenError, setInValidTokenError] = useState(null);

  useEffect(() => {
    try {
      const decoded = jwtDecode(token);
      setPayload(decoded);
      if (!decoded) setInValidTokenError("Token is invalid");
    } catch (error) {
      setInValidTokenError("Token is invalid");
    }
  }, [token]);

  const postData = (e) => {
    e.preventDefault();
    if (couponCode.length <= 0) return setError("Coupon code cannot be empty");
    const temp = { ...payload };
    temp.sub_code = couponCode;
    setPayload(temp);
    instance
      .post("/user/web/subcription", payload)
      .then(() => {
        setSuccess(true);
      })
      .catch((err) => {
        setError("Coupon Activation Error");
      });
  };

  return (
    <>
      <LandingLayout>
        <WemaVerificationWrap>
          <FlexibleDiv flexDir="column">
            <FlexibleDiv className="largeHeader" height="200px" mt="90px">
              {/* <FlexibleDiv width="80%" justifyContent="flex-start"> */}
              <Title>Apply Coupon</Title>
              <Title level={3}>Apply Coupon</Title>
              {/* </FlexibleDiv> */}
            </FlexibleDiv>
            <FlexibleDiv
              width="600px"
              height="max-content"
              flexDir="column"
              className="formWrap"
            >
              {success ? (
                <Alert
                  message="Coupon code applied successfully!!! Proceed to app"
                  type="success"
                />
              ) : !inValidTokenError ? (
                <form>
                  {error && <Alert message={error} type="error" />}
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter Email"
                    // onChange={({ target }) => setEmail(target.value)}
                  />
                  <label htmlFor="coupon">Enter Coupon Code</label>
                  <input
                    type="text"
                    id="coupon"
                    placeholder="Enter Coupon Code"
                    onChange={({ target }) => setCouponCode(target.value)}
                  />
                  <FlexibleDiv
                    className="buttonWrap"
                    justifyContent="flex-start"
                    margin="20px 0 0 0"
                  >
                    <Button
                      text="Claim Code"
                      height="55px"
                      width="max-content"
                      largeFont={true}
                      padding="0 20px"
                      onClick={postData}
                    />
                  </FlexibleDiv>
                </form>
              ) : (
                <Alert message={inValidTokenError} type="error" />
              )}
            </FlexibleDiv>
          </FlexibleDiv>
        </WemaVerificationWrap>
      </LandingLayout>
    </>
  );
};

export default EmailVerificationPage;
