import styled from "styled-components";
import LineBg from "../../assets/pngs/subscriptionPageBg.png";

export const SubscriptionWrap = styled.div`
  width: 100%;
  position: relative;
  min-height: 100vh;
  padding-bottom: 100px;

  @media (max-width: 800px) {
    padding-bottom: 150px;
  }
  .cardswrap {
    background-position: 100% 100%;
    background-image: url(${LineBg});
    padding: 200px 1rem 0;
    flex-direction: column;

    & > div {
      @media (max-width: 800px) {
        flex-direction: column;
      }
    }

    h3 {
      color: #101828;
      text-align: center;
      font-family: "Montserrat", Arial, Helvetica, sans-serif;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 44px;
      letter-spacing: -1.08px;
    }

    .text {
      color: #667085;
      text-align: center;
      font-family: "Montserrat", Arial, Helvetica, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      display: inline-block;
    }

    .sub-card__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;
      align-items: stretch;
      margin-top: 3rem;

      @media (max-width: 800px) {
        flex-direction: row;
        padding: 0 1rem;
      }
    }

    .card {
      width: 372px;
      height: 362px;
      margin: 100px 0 -50px 0;
      border-radius: 16px;
      padding: 40px 0;

      @media (max-width: 400px) {
        width: 90%;
      }

      h5 {
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 0.02em;
        color: #ffffff;
        text-align: center;
        @media (max-width: 400px) {
          font-size: 16px;
          width: 90%;
        }
      }
      h4 {
        font-weight: 700;
        font-size: 18px;
        letter-spacing: 0.02em;
        color: #ffffff;
        text-align: center;
        margin: 5px 0;
      }
      .bodyText {
        width: 80%;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: #e8edf8;
        @media (max-width: 400px) {
          width: 90%;
        }
      }
    }
  }
  h2 {
    font-weight: 500;
    font-size: 28px;
    width: 650px;
    line-height: 35px;
    text-align: center;
    letter-spacing: -0.065em;
    color: #2f2d51;
    margin: 200px 0 50px 0;
    @media (max-width: 800px) {
      width: 90%;
      font-size: 22px;
      margin: 150px 0 50px 0;
    }
  }

  .backgroundBubbles {
    position: absolute;
    z-index: -1;
    bottom: 0;
    width: 100%;
    bottom: -200px;

    @media (max-width: 800px) {
      width: 120%;
    }
    @media (max-width: 500px) {
      bottom: 40px;
    }
  }
`;

export const SubscriptionCard = styled.div`
  max-width: 232px;
  max-height: 327px;
  border-radius: 8px;
  padding: 24px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  box-sizing: border-box;
  font-smoothing: antialiased !important;

  &:hover {
    box-shadow: ${(props) => `rgba(${props.color || "0, 0, 0"}, 0.19)`} 0px 15px
        20px,
      ${(props) => `rgba(${props.color || "0, 0, 0"}, 0.23)`} 0px 8px 6px;
    transform: scale(1.02);
  }

  @media (max-width: 800px) {
    max-width: 343px;
    width: 100%;
  }

  h5 {
    font-family: "Public Sans" Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;

    @media (max-width: 800px) {
      text-align: center;
    }
  }

  .pricing {
    margin: 1em 0 1.2rem;

    @media (max-width: 800px) {
      text-align: center;
    }
    h3 {
      font-family: "Montserrat", Arial, Helvetica, sans-serif;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: inline;
    }
    span {
      font-family: "Montserrat" Arial, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
  .perks {
    font-family: "DM Sans" Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .perk {
      margin: 1rem 0;
      display: flex;
    }

    span.checkmark {
      font-weight: 600;
      padding-right: 0.5rem;
    }
  }
`;
