import React from "react";
import LandingLayout from "../../components/LandingLayout";
import { FlexibleDiv } from "../../components/Box/styles";
import { FaqWrap } from "./styles";
import { Typography } from "antd";
import FaqVector from "../../assets/pngs/faqVector.png";
import FAQ from "../../components/FAQS";

const FAQPage = () => {
  const { Title } = Typography;
  return (
    <LandingLayout>
      <FaqWrap>
        <FlexibleDiv flexDir="column">
          <FlexibleDiv className="largeHeader" justifyContent="space-between">
            <FlexibleDiv width="50%" className="headerTextWrap">
              <Title>faq</Title>
              <Title level={3}>
                Frequently Asked <br />
                Questions
              </Title>
            </FlexibleDiv>
            <FlexibleDiv width="50%" justifyContent="flex-end">
              <img src={FaqVector} alt="" />
            </FlexibleDiv>
          </FlexibleDiv>

          <FlexibleDiv justifyContent="space-between" className="faqMainWrap">
            <FlexibleDiv
              width="600px"
              className="faqContentWrap"
              height="max-content"
            >
              <FAQ fullView />
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>
      </FaqWrap>
    </LandingLayout>
  );
};

export default FAQPage;
