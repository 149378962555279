import styled from "styled-components";

export const WemaVerificationWrap = styled.div`
  width: 100%;
  min-height: 80vh;

  .largeHeader {
    height: 300px;
    margin-top: 100px;
    background: #4d2456;
    position: relative;
    @media (max-width: 800px) {
      margin-top: 80px;
      height: 200px;
    }

    h1 {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 150px;
      letter-spacing: 0.03em;
      color: #c4c4c4;
      opacity: 0.3;
      margin: 0;
      -webkit-text-stroke: 3px #fefefe;

      font {
        font-family: Montserrat;
      }
      @media (max-width: 900px) {
        font-size: 120px;
      }
      @media (max-width: 800px) {
        font-size: 100px;
      }
      @media (max-width: 600px) {
        font-size: 70px;
      }
      @media (max-width: 400px) {
        text-align: center;
        /* font-size: 20px; */
      }
    }
    h3 {
      font-weight: bold;
      font-size: 46px;
      letter-spacing: 0.03em;
      color: #ffffff;
      position: absolute;
      left: 8%;
      bottom: 35%;

      @media (max-width: 1000px) {
        left: 3%;
      }
      @media (max-width: 800px) {
        font-size: 30px;
        bottom: 45%;
      }
      @media (max-width: 600px) {
        font-size: 20px;
      }
      @media (max-width: 400px) {
        bottom: 60%;
        font-size: 30px;
      }
    }
  }
  .formWrap {
    // margin-top: 30px;
    @media (max-width: 800px) {
      width: 90%;
      margin-top: 0;
    }

    button{
      margin: auto;
    }
  }
  form {
    h2 {
      font-size: 30px;
      width: 100%;
      margin-bottom: 20px;
      @media (max-width: 800px) {
        font-size: 20px;
        width: 100%;
      }
    }
    width: 100%;
    margin: 100px 0;
    label {
      margin: 20px 0;
      visibility: hidden;
    }
    input,
    textarea {
      width: 100%;
      padding: 20px;
      border: none;
      border-radius: 10px;
      border: 1px solid gray;
      background-color: #f2f2f2;

      :active {
        box-shadow: none;
        border: none;
      }
    }
    textarea {
      min-height: 200px;
    }
  }
`;
