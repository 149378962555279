import React from "react";
import LandingLayout from "../components/LandingLayout";

export default function Testing() {
  return (
    <div>
      <LandingLayout />
    </div>
  );
}
