import React, { useEffect, useState } from "react";
import { FlexibleDiv } from "../../components/Box/styles";
import { Typography } from "antd";
import EmailVerificationLayout from "../../components/EmailVerificationLayout";
import instance from "../../network/axios";
import { useLocation } from "react-router-dom";
import { EmailVerifiedWrap } from "./styles";

const EmailVerificationPage = () => {
  const { Title, Paragraph } = Typography;
  const url = useLocation();
  const urlParams = new URLSearchParams(url.search);
  const token = urlParams.get("token");
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    instance(`/email/confirm/${token}`)
      .then(({ data }) => {
        console.log({ data });
        data.user.isEmailConfirmed && setVerified(data.user.isEmailConfirmed);
        setLoading(false);
      })
      .catch((err) => {
        setVerified(false);
        setLoading(false);
      });
  }, [url, token]);

 return (
    <EmailVerificationLayout>
      <EmailVerifiedWrap>
        <FlexibleDiv flexDir="column">
          <FlexibleDiv className="largeHeader" justifyContent="space-between">
            <FlexibleDiv width="50%" className="headerTextWrap">
              <Title>Verified</Title>
              <Title level={3}>
                Email Verification <br />
                Successful
              </Title>
            </FlexibleDiv>
          </FlexibleDiv>

          <FlexibleDiv flexDir="column" className="message">
            <Paragraph style={{ color: "green" }}>
              Your email address has been verified successfully. You can proceed
              to login on the app
            </Paragraph>
          </FlexibleDiv>
        </FlexibleDiv>
      </EmailVerifiedWrap>
    </EmailVerificationLayout>
  );

};

export default EmailVerificationPage;
