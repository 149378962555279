import ENG_0168 from "../../assets/images/1.jpg";
import ENG_0174 from "../../assets/images/2.jpg";
import ENG_0187 from "../../assets/images/3.jpg";
import ENG_0190 from "../../assets/images/4.jpg";
import ENG_0212 from "../../assets/images/5.jpg";
import ENG_0214 from "../../assets/images/6.jpg";
import ENG_0216 from "../../assets/images/7.jpg";
import ENG_0217 from "../../assets/images/8.jpg";
import ENG_0218 from "../../assets/images/9.jpg";
import ENG_0219 from "../../assets/images/10.jpg";
import ENG_0228 from "../../assets/images/11.jpg";

import { nanoid } from "nanoid";

export const tablbetImages = [
    {
      id: nanoid(),
      name: "ENG_0174",
      image: ENG_0174,
    },
    {
      id: nanoid(),
      name: "ENG_0187",
      image: ENG_0187,
    },
    {
      id: nanoid(),
      name: "ENG_0190",
      image: ENG_0190,
    },
    {
      id: nanoid(),
      name: "ENG_0212",
      image: ENG_0212,
    },
    {
      id: nanoid(),
      name: "ENG_0214",
      image: ENG_0214,
    },
    {
      id: nanoid(),
      name: "ENG_0216",
      image: ENG_0216,
    },
    {
      id: nanoid(),
      name: "ENG_0217",
      image: ENG_0217,
    },
    {
      id: nanoid(),
      name: "ENG_0218",
      image: ENG_0218,
    },
    {
      id: nanoid(),
      name: "ENG_0168",
      image: ENG_0168,
    },
    {
      id: nanoid(),
      name: "ENG_0219",
      image: ENG_0219,
    },
    {
      id: nanoid(),
      name: "ENG_0228",
      image: ENG_0228,
    }
  ];
  
