import { Bubble } from "../../assets/svgs";

export const WhatMakesUsUniqueData = [
  {
    content:
      "These days, the importance of using past questions in preparing for examinations cannot be over-emphasized. On Exam Padi, the CBT-styled practice questions help to test  students’ knowledge on different subjects ranging from ARTS, SCIENCE AND COMMERCIAL. Also, beyond the access to varieties of Practice Past Questions and Answers, the platform further comes with a strict time limit and a view your score feature in addition to reviewing the complete solutions to all the questions to see where students need improvement.",
    title: "CBT-Styled Practice Questions ",
    icon: <Bubble color="#6BBB99" />,
  },
  {
    content:
      "Knowing where to begin when preparing for an exam for some students can be a difficult task especially when students have to do this on their own. To ensure students always have easy access to learning resources, our experts have carefully prepared and curated Nigerian Educational Research and Development Council (NERDC) approved contents. These include video lessons, quizzes, CBT practice questions and tests in line with NERDC guidelines. Exam padi provides a holistic learning experience for students in Junior and Senior School as-well-as candidates preparing for examinations such as Junior WAEC, Senior WAEC, NECO, JAMB, and SAT.",
    title: "Relevant Content",
    icon: <Bubble color="#4D2456" />,
  },
  {
    content:
      "The use of technology in education ushers in fundamental structural changes that can be integral to achieving significant improvement in productivity. Used to support both teaching and learning, technology infuses classrooms with digital learning tools, such as computers and handheld devices; expands course offerings, experiences, and learning materials; supports learning 24-hours a day, 7 days a week; builds 21st-century skills; increases student’s engagement and motivation; and accelerates learning. Exam Padi will continue to serve the students in an engaging manner, as well as providing fun and efficient learning experience that will promote a deep understanding of the content.",
    title: "Learning with Technology",
    icon: <Bubble color="#FBBE07" />,
  },
];
