import styled from "styled-components";

export const PrivacyPolicyWrap = styled.div`
  width: 100%;
  min-height: 80vh;
  padding: 150px 50px;

  span {
    font-weight: 600;
  }
  li {
    list-style-type: disc;
    margin: 5px 40px;
  }

  @media (max-width: 800px) {
    padding: 120px 0 100px 0;

    .parentWrap {
      width: 90%;

      li {
        margin: 5px 20px;
      }
    }
  }
`;
