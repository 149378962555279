import React from "react";
import LandingLayout from "../../components/LandingLayout";
import { BuyTabletPageWrap } from "./styles";
import { Typography } from "antd";
import { FlexibleDiv } from "../../components/Box/styles";
import Bubbles from "../../assets/pngs/bubbleBackground.png";
import Button from "../../components/Button";
import ENG_0190 from "../../assets/images/4.jpg";
import { Link } from "react-router-dom";
import { specifications } from "../../utils/dataHelpers/specifications";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
// import PictureViewerWithLens from "../../components/PictureViewerWithLens";
import { tablbetImages } from "../../utils/dataHelpers/tabletImages";
import { nanoid } from "nanoid";
import AnimatedArrow from "../../components/AnimatedArrow/AnimantedArrow";
import { useState } from "react";

const BuyTabletPage = () => {
  const { Title, Text } = Typography;
  const [swiped, setSwiped] = useState(false);

  return (
    <LandingLayout>
      <BuyTabletPageWrap>
        <FlexibleDiv>
          <FlexibleDiv className="cardswrap">
            <FlexibleDiv
              justifyContent="space-between"
              className="tabletdisplaywrap"
            >
              <FlexibleDiv className="tabletcaption">
                <FlexibleDiv
                  className="tabletcaption__leftDiv__sub"
                  width="max-content"
                  flexDir="column"
                  alignItems="flex-start"
                >
                  <Title>
                    Be exam ready <br /> with{" "}
                    <span style={{ color: "#FDBD09" }}>Exam Padi</span>
                  </Title>
                  <Text
                    type="secondary"
                    style={{ color: "#fff", marginBottom: "2rem" }}
                    className="heroSection__leftDiv__smallText"
                  >
                    Get one year free subscription when you buy an Exam Padi
                    tablet
                  </Text>
                  <Link to="shop">
                    <Button
                      text="Buy Tablet"
                      height="55px"
                      width="max-content"
                      padding="0 30px"
                      boxShadow={false}
                    />
                  </Link>
                </FlexibleDiv>
              </FlexibleDiv>
              <FlexibleDiv className="tablbetimg">
                <div className="tablet-circle">
                  <img src={ENG_0190} alt="" className="exam" />
                </div>
                {/* <span className="tabletImgbg"></span> */}
              </FlexibleDiv>
            </FlexibleDiv>

            <FlexibleDiv height="auto" className="buy">
              <Title style={{ marginBotton: "1rem" }} level={2}>
                Device Information
              </Title>
              <FlexibleDiv
                justifyContent="flex-start"
                className="specifications"
              >
                <FlexibleDiv
                  width="50%"
                  height="max-content"
                  className="spec_img"
                >
                  {/* <img src={ExamPadiTablet} alt="" className="exam" /> */}
                  <Swiper
                    effect={"cards"}
                    grabCursor={true}
                    modules={[EffectCards]}
                    className="mySwiper"
                    onSlideChange={() => setSwiped(true)}
                  >
                    {tablbetImages.map((img, index) => (
                      <SwiperSlide key={nanoid()}>
                        <img src={img.image} alt={img.name} className="exam" />
                      </SwiperSlide>
                    ))}
                    <AnimatedArrow swiped={swiped} />
                  </Swiper>
                </FlexibleDiv>

                <FlexibleDiv
                  width="45%"
                  height="max-content"
                  justifyContent="flex-start"
                  className="card_wrapper"
                >
                  {specifications.map((spec, id) => (
                    <FlexibleDiv
                      className="card"
                      key={id}
                      height="max-content"
                      justifyContent="space-between"
                    >
                      <img src={spec.logo} alt="" />
                      <Text>{spec.name}</Text>
                    </FlexibleDiv>
                  ))}
                </FlexibleDiv>
              </FlexibleDiv>
            </FlexibleDiv>
          </FlexibleDiv>
          <FlexibleDiv className="accessories">
            <FlexibleDiv className="accessories_wrapper">
              <FlexibleDiv>
                <Title style={{ marginBotton: "2rem" }} level={2}>
                  Accessories
                </Title>
              </FlexibleDiv>

              <FlexibleDiv
                className="accessory_item"
                height="max-content"
                justifyContent="space-between"
              >
                <Text>Leather case</Text>
              </FlexibleDiv>

              <FlexibleDiv
                className="accessory_item"
                height="max-content"
                justifyContent="space-between"
              >
                <Text>Screen protector</Text>
              </FlexibleDiv>
              <FlexibleDiv
                className="accessory_item"
                height="max-content"
                justifyContent="space-between"
              >
                <Text>Type C charger</Text>
              </FlexibleDiv>
              <FlexibleDiv
                className="accessory_item"
                height="max-content"
                justifyContent="space-between"
              >
                <Text>Data cable</Text>
              </FlexibleDiv>
              <FlexibleDiv
                className="accessory_item"
                height="max-content"
                justifyContent="space-between"
              >
                <Text style={{ fontWeight: "bold" }}>
                  Note that there is a warranty card information in the pack
                  which states what warranty covers
                </Text>
              </FlexibleDiv>
            </FlexibleDiv>

            <FlexibleDiv className="price_card">
              <Title level={4}>ExamPadi Tab</Title>
              <FlexibleDiv flexDir="column" margin="10px 0">
                <Title level={5}>+ 1 Year free Subscription </Title>
                <Text
                  className="bodyText"
                  style={{
                    display: "block",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  ₦ 120,000
                </Text>
              </FlexibleDiv>
              <Link to="shop-redirect">
                <Button
                  text="Buy Now"
                  height="55px"
                  width="max-content"
                  padding="0 30px"
                  bgColor="#FFF"
                  color="#2B0434"
                  largeFont
                  className="shop"
                  boxShadow={false}
                />
              </Link>
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>
        <img src={Bubbles} alt="" className="backgroundBubbles" />
      </BuyTabletPageWrap>
    </LandingLayout>
  );
};

export default BuyTabletPage;
