import React from "react";
import { FlexibleDiv } from "../../../components/Box/styles";
import { FAQSectionWrap } from "./styles";
import FAQ from "../../../components/FAQS";
import Button from "../../../components/Button";
import { Typography } from "antd";

const FAQSection = () => {
  const { Title } = Typography;
  return (
    <FAQSectionWrap>
      <FlexibleDiv flexDir="column">
        <Title>Frequently Asked Questions</Title>
        <FlexibleDiv
          width="90%"
          height="max-content"
          className="FAQSectionParentWrap"
        >
          <FAQ />
          <FlexibleDiv justifyContent="flex-end" margin="20px 0">
            <a href="/faq">View More</a>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>

      {/* last session */}
      <FlexibleDiv flexDir="column">
        <h2>Start Learning Today!</h2>
        <FlexibleDiv className="buttonWrap" height="200px">
          <a href="#download" target="">
            <Button
              text="Download the App"
              height="64px"
              largeFont
              width="max-content"
              padding="0 10px"
            />
          </a>
        </FlexibleDiv>
      </FlexibleDiv>
    </FAQSectionWrap>
  );
};

export default FAQSection;
