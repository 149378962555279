import styled from "styled-components";

export const TakeLessonsWithYouWrap = styled.div`
  width: 100%;
  margin: 120px 0 0 0;

  @media (max-width: 800px) {
    margin: 0;
  }

  .takeLessonsWithYou__card {
    background: #4d2456;
    overflow: hidden;

    @media (min-width: 800px) {
      width: 80%;
      border-radius: 33px;
      flex-direction: row;
    }
    .takeLessonsWithYou__cardImage {
      img {
        width: 100%;
        height: auto;
        margin-bottom: -10px;
      }
      @media (min-width: 800px) {
        width: 40%;
      }
    }
    .takeLessonsWithYou__cardBody {
      h1 {
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 0.05em;
        color: #ffffff;
        text-align: center;
        @media (min-width: 1000px) {
          font-size: 30px;
        }

        @media (max-width: 400px) {
          font-size: 18px;
          width: 90%;
        }
      }
      .subHeader {
        font-weight: 300;
        font-size: 16px;
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 0.04em;
        margin-bottom: 30px;
        color: #c6a3ce;
        @media (max-width: 800px) {
          text-align: center;
          width: 100%;
        }
        @media (max-width: 400px) {
          font-size: 16px;
        }
      }
      @media (min-width: 800px) {
        width: max-content;
        align-items: flex-start;
      }

      .takeLessonsWithYou__linksWrap {
        margin: 1rem 0;
        @media (max-width: 800px) {
          text-align: center;
          width: 100%;
        }

        @media (min-width: 800px) {
          justify-content: space-between;
          flex-direction: row;
        }

        & > div {
          @media (max-width: 800px) {
            margin-bottom: 20px;
          }

          .takeLessonsWithYou__linksHeaderText {
            font-weight: 600;
            font-size: 16px;
            letter-spacing: 0.04em;
            color: #ffffff;
            margin-bottom: 5px;

            & + div {
              & a:first-child {
                margin-right: 0.5rem;
              }
              & a:last-child {
                margin-left: 0.5rem;
              }
            }
          }
        }
        .takeLessonsWithYou__links {
          border-radius: 6px;
          border: 1px solid #bbb;
          transition: all 0.3s ease;
          cursor: pointer;
          :hover {
            opacity: 0.7;
          }

          span {
            color: #ffffff;
            font-size: 16px;
          }
          img {
            width: 14px;
            height: auto;
          }
        }
      }

      @media (max-width: 800px) {
        margin-top: 30px;
        width: 100%;
        align-items: center;
      }
    }
  }
`;
