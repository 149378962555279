import React, { useEffect, useState } from "react";
import { FlexibleDiv } from "../../components/Box/styles";
import { Typography } from "antd";
import EmailVerificationLayout from "../../components/EmailVerificationLayout";
import instance from "../../network/axios";
import { useLocation } from "react-router-dom";
import { EmailVerifiedWrap } from "../EmailVerifiedPage/styles";
import data, { header } from "./deleteAccountData";

const EmailVerificationPage = () => {
  const { Title, Paragraph, Text } = Typography;
  const url = useLocation();
  const urlParams = new URLSearchParams(url.search);
  const token = urlParams.get("token");
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    instance(`/email/confirm/${token}`)
      .then(({ data }) => {
        console.log({ data });
        data.user.isEmailConfirmed && setVerified(data.user.isEmailConfirmed);
        setLoading(false);
      })
      .catch((err) => {
        setVerified(false);
        setLoading(false);
      });
  }, [url, token]);

  return (
    <EmailVerificationLayout>
      <EmailVerifiedWrap>
        <FlexibleDiv flexDir="column">
          <FlexibleDiv className="largeHeader" justifyContent="space-between">
            <FlexibleDiv className="headerTextWrap ">
              <Title>Delete Account?</Title>
              <Title level={3}>{header}</Title>
            </FlexibleDiv>
          </FlexibleDiv>
          <FlexibleDiv flexDir="column" className="message">
            <FlexibleDiv
              flexDir="column"
              alignItems="flex-start"
              width="70%"
              className="parentWrap"
            >
              {data.map((dt) => (
                <>
                  {dt?.title && <Title level={5}>{dt?.title}</Title>}
                  {dt?.description && (
                    <Text
                      style={{
                        margin: "1rem 0",
                        color: dt.priority ? "#FF0000" : "",
                      }}
                    >
                      {dt?.description}
                    </Text>
                  )}
                  {dt?.list && (
                    <>
                      <ul
                        style={{
                          paddingLeft: "2rem",
                          listStyleType:
                            dt.listStyle === "disc" ? "disc" : "decimal",
                        }}
                      >
                        {dt.list.map((list) => (
                          <li
                            style={{
                              listStyleType:
                                dt.listStyle === "disc" ? "disc" : "decimal",
                              margin: "0.5rem 0",
                            }}
                          >
                            {list}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </>
              ))}
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>
      </EmailVerifiedWrap>
    </EmailVerificationLayout>
  );
};

export default EmailVerificationPage;
