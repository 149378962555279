import React from "react";
import { FlexibleDiv } from "../../../components/Box/styles";
import { HeroSectionWrap } from "./styles";
import Button from "../../../components/Button";
import { Typography } from "antd";
import RocketImage from "../../../assets/pngs/heroLeftDivBg.png";
import LearningImage from "../../../assets/pngs/heroStudyimage.png";
import LearningImageMobile from "../../../assets/pngs/studyPicMobile.png";

const HeroSection = () => {
  const { Text, Title } = Typography;

  return (
    <HeroSectionWrap>
      <FlexibleDiv justifyContent="space-between" className="heroSection">
        <FlexibleDiv
          width="40%"
          justifyContent="flex-end"
          className="heroSection__leftDiv"
          height="650px"
        >
          <FlexibleDiv
            className="heroSection__leftDiv__sub"
            width="max-content"
            flexDir="column"
            alignItems="flex-start"
          >
            <img
              src={RocketImage}
              alt=""
              className="heroSection__leftDiv__rocketImage"
            />
            <Title>
              Be exam ready <br />
              with <span style={{ color: "#470556" }}>Exam Padi</span>
            </Title>
            <Text type="secondary" className="heroSection__leftDiv__smallText">
              Helping exit-class students prepare for entrance and school
              leaving examinations
            </Text>
            <a href="#download">
              <Button
                text="Start Learning"
                height="55px"
                width="max-content"
                padding="0 10px"
              />
            </a>
          </FlexibleDiv>
        </FlexibleDiv>
        <div className="heroSection__rightDiv">
          <img src={LearningImage} alt="" className="studyImageDesktop" />
          <img src={LearningImageMobile} alt="" className="studyImageMobile" />
        </div>
      </FlexibleDiv>
    </HeroSectionWrap>
  );
};

export default HeroSection;
