import styled from "styled-components";

export const FAQSWrap = styled.div`
  width: 100%;

  .contentWrap {
    transition: all 0.3s ease;
    overflow: hidden;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;

    .questionWrap {
      min-height: 50px;
      height: max-content;
    }
  }

  p {
    width: 90%;
    font-size: 18px;
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
  span {
    font-size: 16px;
    width: 90%;
    line-height: 20px;
    height: max-content;

    @media (max-width: 600px) {
      font-size: 16px;
    }
  }

  svg {
    transition: all 0.3s ease;
    :hover {
      opacity: 0.7;
    }
  }
`;

export const FaqBox = styled.div`
  height: ${({ index, showIndex }) => (index === showIndex ? "170px" : "50px")};
  min-height: ${({ index, showIndex }) => index === showIndex && "max-content"};

  @media (max-width: 1200px) and (min-width: 800px) {
    height: ${({ index, showIndex }) =>
      index === showIndex ? "250px" : "50px"};
  }

  @media (max-width: 480px) {
    height: ${({ index, showIndex }) =>
      index === showIndex ? "250px" : "50px"};
  }
`;
