export const countriesList = [
    { value: 'afghanistan', label: 'Afghanistan' },
    { value: 'albania', label: 'Albania' },
    { value: 'algeria', label: 'Algeria' },
    { value: 'andorra', label: 'Andorra' },
    { value: 'angola', label: 'Angola' },
    { value: 'antigua_and_barbuda', label: 'Antigua and Barbuda' },
    { value: 'argentina', label: 'Argentina' },
    { value: 'armenia', label: 'Armenia' },
    { value: 'australia', label: 'Australia' },
    { value: 'austria', label: 'Austria' },
    { value: 'azerbaijan', label: 'Azerbaijan' },
    { value: 'bahamas', label: 'Bahamas' },
    { value: 'bahrain', label: 'Bahrain' },
    { value: 'bangladesh', label: 'Bangladesh' },
    { value: 'barbados', label: 'Barbados' },
    { value: 'belarus', label: 'Belarus' },
    { value: 'belgium', label: 'Belgium' },
    { value: 'belize', label: 'Belize' },
    { value: 'benin', label: 'Benin' },
    { value: 'bhutan', label: 'Bhutan' },
    { value: 'bolivia', label: 'Bolivia' },
    { value: 'bosnia_and_herzegovina', label: 'Bosnia and Herzegovina' },
    { value: 'botswana', label: 'Botswana' },
    { value: 'brazil', label: 'Brazil' },
    { value: 'brunei', label: 'Brunei' },
    { value: 'bulgaria', label: 'Bulgaria' },
    { value: 'burkina_faso', label: 'Burkina Faso' },
    { value: 'burundi', label: 'Burundi' },
    { value: 'cabo_verde', label: 'Cabo Verde' },
    { value: 'cambodia', label: 'Cambodia' },
    { value: 'cameroon', label: 'Cameroon' },
    { value: 'canada', label: 'Canada' },
    { value: 'central_african_republic', label: 'Central African Republic' },
    { value: 'chad', label: 'Chad' },
    { value: 'chile', label: 'Chile' },
    { value: 'china', label: 'China' },
    { value: 'colombia', label: 'Colombia' },
    { value: 'comoros', label: 'Comoros' },
    { value: 'congo_democratic_republic_of_the', label: 'Congo, Democratic Republic of the' },
    { value: 'congo,_republic_of_the', label: 'Congo, Republic of the' },
    { value: 'costa_rica', label: 'Costa Rica' },
    { value: "cote_d'ivoire", label: "Cote d'Ivoire" },
    { value: 'croatia', label: 'Croatia' },
    { value: 'cuba', label: 'Cuba' },
    { value: 'cyprus', label: 'Cyprus' },
    { value: 'czech_republic', label: 'Czech Republic' },
    { value: 'denmark', label: 'Denmark' },
    { value: 'djibouti', label: 'Djibouti' },
    { value: 'dominica', label: 'Dominica' },
    { value: 'dominican_republic', label: 'Dominican Republic' },
    { value: 'ecuador', label: 'Ecuador' },
    { value: 'egypt', label: 'Egypt' },
    { value: 'el_salvador', label: 'El Salvador' },
    { value: 'equatorial_guinea', label: 'Equatorial Guinea' },
    { value: 'eritrea', label: 'Eritrea' },
    { value: 'estonia', label: 'Estonia' },
    { value: 'eswatini', label: 'Eswatini' },
    { value: 'ethiopia', label: 'Ethiopia' },
    { value: 'fiji', label: 'Fiji' },
    { value: 'finland', label: 'Finland' },
    { value: 'france', label: 'France' },
    { value: 'gabon', label: 'Gabon' },
    { value: 'gambia', label: 'Gambia' },
    { value: 'georgia', label: 'Georgia' },
    { value: 'germany', label: 'Germany' },
    { value: 'ghana', label: 'Ghana' },
    { value: 'greece', label: 'Greece' },
    { value: 'grenada', label: 'Grenada' },
    { value: 'guatemala', label: 'Guatemala' },
    { value: 'guinea', label: 'Guinea' },
    { value: 'guinea-bissau', label: 'Guinea-Bissau' },
    { value: 'guyana', label: 'Guyana' },
    { value: 'haiti', label: 'Haiti' },
    { value: 'honduras', label: 'Honduras' },
    { value: 'hungary', label: 'Hungary' },
    { value: 'iceland', label: 'Iceland' },
    { value: 'india', label: 'India' },
    { value: 'indonesia', label: 'Indonesia' },
    { value: 'iran', label: 'Iran' },
    { value: 'iraq', label: 'Iraq' },
    { value: 'ireland', label: 'Ireland' },
    { value: 'israel', label: 'Israel' },
    { value: 'italy', label: 'Italy' },
    { value: 'jamaica', label: 'Jamaica' },
    { value: 'japan', label: 'Japan' },
    { value: 'jordan', label: 'Jordan' },
    { value: 'kazakhstan', label: 'Kazakhstan' },
    { value: 'kenya', label: 'Kenya' },
    { value: 'kiribati', label: 'Kiribati' },
    { value: 'korea,_north', label: 'Korea, North' },
    { value: 'korea,_south', label: 'Korea, South' },
    { value: 'kosovo', label: 'Kosovo' },
    { value: 'kuwait', label: 'Kuwait' },
    { value: 'kyrgyzstan', label: 'Kyrgyzstan' },
    { value: 'laos', label: 'Laos' },
    { value: 'latvia', label: 'Latvia' },
    { value: 'lebanon', label: 'Lebanon' },
    { value: 'lesotho', label: 'Lesotho' },
    { value: 'liberia', label: 'Liberia' },
    { value: 'libya', label: 'Libya' },
    { value: 'liechtenstein', label: 'Liechtenstein' },
    { value: 'lithuania', label: 'Lithuania' },
    { value: 'luxembourg', label: 'Luxembourg' },
    { value: 'madagascar', label: 'Madagascar' },
    { value: 'malawi', label: 'Malawi' },
    { value: 'malaysia', label: 'Malaysia' },
    { value: 'maldives', label: 'Maldives' },
    { value: 'mali', label: 'Mali' },
    { value: 'malta', label: 'Malta' },
    { value: 'marshall_islands', label: 'Marshall Islands' },
    { value: 'mauritania', label: 'Mauritania' },
    { value: 'mauritius', label: 'Mauritius' },
    { value: 'mexico', label: 'Mexico' },
    { value: 'micronesia', label: 'Micronesia' },
    { value: 'moldova', label: 'Moldova' },
    { value: 'monaco', label: 'Monaco' },
    { value: 'mongolia', label: 'Mongolia' },
    { value: 'montenegro', label: 'Montenegro' },
    { value: 'morocco', label: 'Morocco' },
    { value: 'mozambique', label: 'Mozambique' },
    { value: 'myanmar', label: 'Myanmar' },
    { value: 'namibia', label: 'Namibia' },
    { value: 'nauru', label: 'Nauru' },
    { value: 'nepal', label: 'Nepal' },
    { value: 'netherlands', label: 'Netherlands' },
    { value: 'new_zealand', label: 'New Zealand' },
    { value: 'nicaragua', label: 'Nicaragua' },
    { value: 'niger', label: 'Niger' },
    { value: 'nigeria', label: 'Nigeria' },
    { value: 'north_macedonia', label: 'North Macedonia' },
    { value: 'norway', label: 'Norway' },
    { value: 'oman', label: 'Oman' },
    { value: 'pakistan', label: 'Pakistan' },
    { value: 'palau', label: 'Palau' },
    { value: 'palestine', label: 'Palestine' },
    { value: 'panama', label: 'Panama' },
    { value: 'papua_new_guinea', label: 'Papua New Guinea' },
    { value: 'paraguay', label: 'Paraguay' },
    { value: 'peru', label: 'Peru' },
    { value: 'philippines', label: 'Philippines' },
    { value: 'poland', label: 'Poland' },
    { value: 'portugal', label: 'Portugal' },
    { value: 'qatar', label: 'Qatar' },
    { value: 'romania', label: 'Romania' },
    { value: 'russia', label: 'Russia' },
    { value: 'rwanda', label: 'Rwanda' },
    { value: 'saint_kitts_and_nevis', label: 'Saint Kitts and Nevis' },
    { value: 'saint_lucia', label: 'Saint Lucia' },
    { value: 'saint_vincent_and_the_grenadines', label: 'Saint Vincent and the Grenadines' },
    { value: 'samoa', label: 'Samoa' },
    { value: 'san_marino', label: 'San Marino' },
    { value: 'sao_tome_and_principe', label: 'Sao Tome and Principe' },
    { value: 'saudi_arabia', label: 'Saudi Arabia' },
    { value: 'senegal', label: 'Senegal' },
    { value: 'serbia', label: 'Serbia' },
    { value: 'seychelles', label: 'Seychelles' },
    { value: 'sierra_leone', label: 'Sierra Leone' },
    { value: 'singapore', label: 'Singapore' },
    { value: 'slovakia', label: 'Slovakia' },
    { value: 'slovenia', label: 'Slovenia' },
    { value: 'solomon_islands', label: 'Solomon Islands' },
    { value: 'somalia', label: 'Somalia' },
    { value: 'south_africa', label: 'South Africa' },
    { value: 'south_sudan', label: 'South Sudan' },
    { value: 'spain', label: 'Spain' },
    { value: 'sri_lanka', label: 'Sri Lanka' },
    { value: 'sudan', label: 'Sudan' },
    { value: 'suriname', label: 'Suriname' },
    { value: 'sweden', label: 'Sweden' },
    { value: 'switzerland', label: 'Switzerland' },
    { value: 'syria', label: 'Syria' },
    { value: 'taiwan', label: 'Taiwan' },
    { value: 'tajikistan', label: 'Tajikistan' },
    { value: 'tanzania', label: 'Tanzania' },
    { value: 'thailand', label: 'Thailand' },
    { value: 'timor-leste', label: 'Timor-Leste' },
    { value: 'togo', label: 'Togo' },
    { value: 'tonga', label: 'Tonga' },
    { value: 'trinidad_and_tobago', label: 'Trinidad and Tobago' },
    { value: 'tunisia', label: 'Tunisia' },
    { value: 'turkey', label: 'Turkey' },
    { value: 'turkmenistan', label: 'Turkmenistan' },
    { value: 'tuvalu', label: 'Tuvalu' },
    { value: 'uganda', label: 'Uganda' },
    { value: 'ukraine', label: 'Ukraine' },
    { value: 'united_arab_emirates', label: 'United Arab Emirates' },
    { value: 'united_kingdom', label: 'United Kingdom' },
    { value: 'united_states', label: 'United States' },
    { value: 'uruguay', label: 'Uruguay' },
    { value: 'uzbekistan', label: 'Uzbekistan' },
    { value: 'vanuatu', label: 'Vanuatu' },
    { value: 'vatican_city', label: 'Vatican City' },
    { value: 'venezuela', label: 'Venezuela' },
    { value: 'vietnam', label: 'Vietnam' },
    { value: 'yemen', label: 'Yemen' },
    { value: 'zambia', label: 'Zambia' },
    { value: 'zimbabwe', label: 'Zimbabwe' }
  ];
  export const states = [
    { value: 'abia', label: 'Abia' },
    { value: 'adamawa', label: 'Adamawa' },
    { value: 'akwa_ibom', label: 'Akwa Ibom' },
    { value: 'anambra', label: 'Anambra' },
    { value: 'bauchi', label: 'Bauchi' },
    { value: 'bayelsa', label: 'Bayelsa' },
    { value: 'benue', label: 'Benue' },
    { value: 'borno', label: 'Borno' },
    { value: 'cross_river', label: 'Cross River' },
    { value: 'delta', label: 'Delta' },
    { value: 'ebonyi', label: 'Ebonyi' },
    { value: 'edo', label: 'Edo' },
    { value: 'ekiti', label: 'Ekiti' },
    { value: 'enugu', label: 'Enugu' },
    { value: 'fct', label: 'Federal Capital Territory' },
    { value: 'gombe', label: 'Gombe' },
    { value: 'imo', label: 'Imo' },
    { value: 'jigawa', label: 'Jigawa' },
    { value: 'kaduna', label: 'Kaduna' },
    { value: 'kano', label: 'Kano' },
    { value: 'katsina', label: 'Katsina' },
    { value: 'kebbi', label: 'Kebbi' },
    { value: 'kogi', label: 'Kogi' },
    { value: 'kwara', label: 'Kwara' },
    { value: 'lagos', label: 'Lagos' },
    { value: 'nasarawa', label: 'Nasarawa' },
    { value: 'niger', label: 'Niger' },
    { value: 'ogun', label: 'Ogun' },
    { value: 'ondo', label: 'Ondo' },
    { value: 'osun', label: 'Osun' },
    { value: 'oyo', label: 'Oyo' },
    { value: 'plateau', label: 'Plateau' },
    { value: 'rivers', label: 'Rivers' },
    { value: 'sokoto', label: 'Sokoto' },
    { value: 'taraba', label: 'Taraba' },
    { value: 'yobe', label: 'Yobe' },
    { value: 'zamfara', label: 'Zamfara' }
  ];
  

  export default countriesList