import { Tabs, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ShopContext } from "../../../context/ShopProvider";
import { ShopItems } from "../../../utils/dataHelpers/shopData";

const styles = {
  descSection: {
    flex: "1",
    display: "flex",
    justifyContent: "center"
  },
  descTitle: {
    fontSize: "1.3rem",
    lineheight: "1",
    margin: "0",
  },
  descSubtitle: {
    fontSize: "1rem",
    lineheight: "1",
    margin: "0",
  },
  descListWrapper: {
    padding: "0 2rem",
  },
  descList: {
    listStyleType: "disc",
    lineHeight: 2,
    padding: "0 2rem"
  },
};

const DescriptionNReview = ({ selectedItem }) => {
  const { Text, Title } = Typography;

  const items = [
    {
      key: "1",
      label: "Description",
      children: (
        <div style={styles.descSection}>
          <div style={styles.descListWrapper}>
            <Title level={5}>{selectedItem.title}</Title>
            <ul style={styles.descList}>
              {selectedItem?.perks?.map((perk, index) => (
                <li key={index} style={styles.descList}>
                  {perk}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Reviews",
      children: (
        <div>
          <Text>There are no reviews yet.</Text>

          <br />
          <br />
          <Text>
            Only logged in customers who have purchased this product may leave a
            review.
          </Text>
        </div>
      ),
    },
  ];
  return <Tabs defaultActiveKey="1" items={items} />;
};

export default DescriptionNReview;
