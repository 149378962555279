import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Collapse,
  Divider,
  Form,
  Input,
  List,
  Select,
  Table,
  Tag,
  Typography,
} from "antd";
import LandingLayout from "../../components/LandingLayout";
import { FlexibleDiv } from "../../components/Box/styles";
import Bubbles from "../../assets/pngs/bubbleBackground.png";
import { useHistory } from "react-router-dom";
import { ShopContext } from "../../context/ShopProvider";
import { ShopWrap, ShoppingLayout } from "./styles";
import { CloseCircleOutlined } from "@ant-design/icons";
import RelatedProducts from "./_components/RelatedProducts";
import countriesList, {
  states,
} from "../../utils/dataHelpers/countries_n_states";

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const Cart = () => {
  const { Text, Title } = Typography;
  const { cartInfo, setCartInfo, currencyFormatter } = useContext(ShopContext);
  const [cartTotal, setCartTotal] = useState({
    subtotal: 0,
    shipping: { city: "", state: "" },
    total: 0,
  });
  const navigate = useHistory();
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const { Option } = Select;
  const [activeKey, setActiveKey] = useState(0);
  const [showStates, setShowStates] = useState(false);
  /**
 * 
 *Exam Padi Education Tablet 
× 2
₦200,000.00
Subtotal	₦200,000.00
Shipping	Enter your address to view shipping options.
Total	₦200,000.00
 */
  const navigateToCheckout = useCallback(
    (id) => {
      console.log({ items: cartInfo.items });
      cartInfo.items.map((item) => ({
        title: `${item.name} x ${item.quantity}`,
      }));

      setCartInfo((prev) => ({
        ...prev,
        totalAmount: cartInfo.items.reduce((accumulator, item) => {
          return accumulator + item.totalAmount;
        }, 0),
      }));

      navigate.push(`/shop/cart/checkout`);
    },
    [cartInfo]
  );

  const add = (id) => {
    const temp = [...cartInfo.items];

    const newTemp = temp.map((item) => ({
      ...item,
      quantity: item.id === id ? item.quantity + 1 : item.quantity,
      totalAmount:
        item.id === id ? item.price * (item.quantity + 1) : item.totalAmount,
    }));
    setCartInfo((prev) => ({ ...prev, items: newTemp }));
  };

  const sub = (id) => {
    const temp = [...cartInfo.items];

    const newTemp = temp.map((item) => ({
      ...item,
      quantity:
        item.id === id
          ? item.quantity <= 0
            ? 0
            : item.quantity - 1
          : item.quantity,
      totalAmount:
        item.id === id
          ? item.price * (item.quantity <= 0 ? 0 : item.quantity - 1)
          : item.totalAmount,
    }));

    setCartInfo((prev) => ({ ...prev, items: newTemp }));
  };

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "img",
      render: (_) => (
        <div className="close">
          <CloseCircleOutlined />
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "img",
      key: "img",
      render: (_, { img }) => (
        <div className="preview-img">
          <img src={img} alt="" width={150} height={100} />
        </div>
      ),
    },
    {
      title: "Product",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, items) => {
        return (
          <div className="cart-settings">
            <div className="add-to-cart-button-wrapper">
              <Button onClick={() => sub(items.id)}>-</Button>
              <Text className="item-count">{items.quantity}</Text>
              <Button onClick={() => add(items.id)}>+</Button>
            </div>
          </div>
        );
      },
    },
    {
      title: "Sub-total",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, items) => {
        return <span>{items.totalAmount}</span>;
      },
    },
  ];

  useEffect(() => {
    let sumTotalAmount = cartInfo.items.reduce((accumulator, item) => {
      return accumulator + item.totalAmount;
    }, 0);

    setCartTotal({
      subtotal: sumTotalAmount,
      shipping: {
        state: "Lagos",
        city: "Ikeja",
      },
      total: sumTotalAmount,
    });
  }, [cartInfo]);

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    setCartInfo((prev) => ({
      ...prev,
      billingAddress: {
        ...prev.billingAddress,
        state: { ...prev.billingAddress.state, values: values.state },
        country: { ...prev.billingAddress.country, values: values.country },
        city: { ...prev.billingAddress.city, values: values.city },
      },
    }));
    setActiveKey(0);
  };

  const onCollapseChange = (key) => {
    setActiveKey(key);
    console.log("object", key);
  };

  const onCountryChange = (value) => {
    console.log(`selected ${value}`);
    if (value === "nigeria") setShowStates(true);
  };
  const onStateChange = (value) => {
    console.log(`selected ${value}`);
    if (value === "nigeria") setShowStates(true);
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  useEffect(() => {
    console.log(cartInfo);
  }, [cartInfo]);

  return (
    <LandingLayout>
      <ShoppingLayout>
        <FlexibleDiv>
          <FlexibleDiv className="shop-area">
            <ShopWrap className="cart">
              <Title level={3}>Your Cart</Title>
              <Table
                columns={columns}
                dataSource={cartInfo.items}
                pagination={false}
              />

              <div className="order-details">
                <Card
                  title="Cart totals"
                  bordered={false}
                  style={{ width: 450 }}
                >
                  <div className="cart-item">
                    <div className="cart-item-title">Subtotal</div>
                    <div className="cart-item-value">
                      {currencyFormatter.format(cartTotal.subtotal)}
                    </div>
                  </div>
                  <Divider orientation="left"></Divider>
                  <div className="cart-item">
                    <div className="cart-item-title">Shipping</div>
                    <div className="cart-item-value">
                      <p>Free shipping </p>
                      <p>
                        Shipping to{" "}
                        <strong>
                          {cartTotal.shipping.city}, {cartTotal.shipping.state}
                        </strong>
                      </p>

                      <Collapse
                        collapsible={false}
                        activeKey={activeKey}
                        expandIcon={({ isActive }) => <></>}
                        onChange={onCollapseChange}
                      >
                        <Panel header="Change Address" key="1">
                          <Form
                            {...formItemLayout}
                            form={form}
                            name="regiaddressster"
                            onFinish={onFinish}
                            scrollToFirstError
                          >
                            <Form.Item
                              name="country"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select Country!",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Country"
                                optionFilterProp="children"
                                onChange={onCountryChange}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={countriesList}
                              />
                            </Form.Item>

                            <Form.Item
                              name="state"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select State!",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="State"
                                optionFilterProp="children"
                                onChange={onStateChange}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={showStates ? states : []}
                              />
                            </Form.Item>

                            <Form.Item
                              name="city"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your username!",
                                },
                              ]}
                            >
                              <Input placeholder="City" />
                            </Form.Item>

                            <Form.Item {...tailFormItemLayout}>
                              <Button type="primary" htmlType="submit">
                                Update
                              </Button>
                            </Form.Item>
                          </Form>
                        </Panel>
                      </Collapse>
                    </div>
                  </div>
                  <Divider orientation="left"></Divider>
                  <div className="cart-item">
                    <div className="cart-item-title">Total</div>
                    <div className="cart-item-value">
                      {currencyFormatter.format(cartTotal.total)}
                    </div>
                  </div>
                  <Divider orientation="left"></Divider>
                  <Button
                    onClick={() => {
                      window.location.href =
                        "https://paystack.com/buy/exampadi-tab-iocvdg";
                    }}
                    className="checkout-btn"
                  >
                    Proceed to Checkout
                  </Button>
                </Card>
              </div>
              {/* <RelatedProducts selectedItem={selectedItem} /> */}
            </ShopWrap>
            <img src={Bubbles} alt="" className="backgroundBubbles" />
          </FlexibleDiv>
        </FlexibleDiv>
      </ShoppingLayout>
    </LandingLayout>
  );
};

export default Cart;
