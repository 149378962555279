import styled from "styled-components";
import { Modal } from "antd";

export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
  }
  .modalCntentWrap {
    padding: 50px 20px;

    .header {
      margin: 10px 0;
      font-weight: bold;
      text-transform: capitalize;
    }
  }
`;
