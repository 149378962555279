import styled from "styled-components";

export const StartLearningSectionWrap = styled.div`
  width: 100%;

  .startLearningSection {
    .startLearningSection__card {
      height: 300px;
      padding: 30px 0;
      background: #313848;
      border-radius: 33px;
      @media (max-width: 1000px) {
        width: 85%;
      }
      @media (max-width: 800px) {
        height: max-content;
      }

      h1 {
        font-weight: normal;
        font-size: 30px;
        letter-spacing: 0.04em;
        color: #ffffff;
        @media (max-width: 800px) {
          width: 100%;
          font-size: 27px;
          text-align: center;
        }
      }
      .startLearningSection__cardBodyWrap {
        @media (max-width: 800px) {
          flex-wrap: wrap;
        }
        .startLearningSection__cardBodyMain {
          @media (max-width: 800px) {
            width: 100%;
            align-items: center;
            margin: 20px 0;
          }
          .startLearningSection__cardBodyTopWrap {
            @media (max-width: 800px) {
              justify-content: center;
            }
            .startLearningSection__cardBodyImageWrap {
              border-radius: 100%;

              img {
                width: 50%;
                height: auto;
              }
            }
            @media (max-width: 800px) {
              .svgLine {
                display: none;
              }
            }
          }
          .startLearningSection__cardBodyText {
            font-size: 16px;
            margin-top: 14px;
            line-height: 23px;
            letter-spacing: 0.07em;
            color: #ffffff;
            width: 180px;
            @media (max-width: 800px) {
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }

    .startLearningSection__readyPlay {
      margin: 120px 0 100px 0;
      @media (max-width: 800px) {
        width: 90%;
        height: max-content;
        margin: 10px 0 100px 0;
      }
      .startLearningSection__readyPlayBody {
        @media (max-width: 800px) {
          width: 95%;
          flex-direction: column !important;
          justify-content: center;
        }
        .startLearningSection__readyPlayBodyImageWrap {
          width: 250px;
          height: 200px;
          border-radius: 20px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          @media (max-width: 800px) {
            width: 100%;
            height: 280px;
          }
          @media (max-width: 500px) {
            height: 200px;
          }
        }

        .startLearningSection__readyPlayBodyContentWrap {
          h1 {
            font-weight: 600;
            font-size: 27px;
            line-height: 148.69%;
            letter-spacing: 0.02em;
            color: #000000;
          }
          .activityImage {
            width: 17px;
            height: 17px;
            margin: -20px 0 0 5px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .bodyText {
            margin-top: 10px;
            font-size: 16px;
            line-height: 23px;
            letter-spacing: 0.02em;
            color: #4f4f4f;
          }
          @media (max-width: 800px) {
            width: 100%;
            margin-top: 30px;
            flex-direction: row;
            flex-wrap: wrap;
            .activityWrap {
              width: max-content;
              margin: 0 10px;
              justify-content: center;
            }
            .bodyText {
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }
  }
`;
