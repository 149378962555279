import styled from "styled-components";
import Bg from "../../../assets/pngs/landingLastSection.png";

export const FAQSectionWrap = styled.div`
  width: 100%;
  margin: 0 0 100px 0;
  h1 {
    font-weight: 600;
    width: 300px;
    font-size: 27px;
    text-align: center;
    @media (min-width: 800px) {
      font-size: 35px;
    }
  }

  .FAQSectionParentWrap {
    @media (min-width: 700px) {
      width: 600px;
    }

    a {
      font-size: 13px;
      letter-spacing: 0.02em;
      text-decoration-line: underline;
      color: #6bbb99;
      transition: all 0.3s ease;

      :hover {
        color: green;
      }
    }
  }

  h2 {
    font-family: "Permanent Marker", cursive;
    font-size: 30px;
    font-weight: 400;
    margin-top: 50px;
    color: #470556;
    text-transform: capitalize;
    letter-spacing: -0.04em;
    text-align: center;
    max-width: 90%;
    font {
      font-family: "Permanent Marker", cursive;
    }
  }

  .buttonWrap {
    background-image: url(${Bg});
    background-size: 100% 100%;
    @media (min-width: 500px) {
      width: 400px;
    }
    button {
      text-transform: none !important;
      width: 240px;
    }
  }
`;
