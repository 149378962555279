import styled from "styled-components";

export const ChatContainer = styled.a`
    width: 60px;
    height: 60px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    margin: 0;
    border-radius: 50%;
    overflow: hidden;
    font: inherit;
    text-transform: none;
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: 1px solid #03a84e;
    line-height: 1.5rem;
    text-decoration: none;
    background-color: #03a84e;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`