// import React from "react";
import FeedOne from "../../assets/images/esther.png";
import FeedTwo from "../../assets/images/oyebamiji.png";
import FeedThree from "../../assets/images/oreoluwa.png";
import FeedFour from "../../assets/images/babajide.png";

export const FeedBackData = [
  {
    title:
      "Thank you to all of our instructors for this wonderful app. Almighty God will continue to strengthen you and supply you with all of your needs. Amen. We can't express our gratitude enough.",
    person: FeedOne,
    name: "Esther Owolabi",
  },
  {
    title:
      "I'd like to thank all of the administrators and teachers for their commitment to this app.  Thank you so much for everything you do.",
    person: FeedTwo,
    name: "Oyebamiji Timothy",
  },
  {
    title:
      "Thank you for developing the exam padi app. The courses and instructions were quite beneficial.",
    person: FeedThree,
    name: "Oreoluwa",
  },
  {
    title:
      "This app is fantastic. The lessons are really powerful and comprehensive.",
    person: FeedFour,
    name: "Babajide",
  },
];
