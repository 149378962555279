export const header = "Delete My Account";

const data = [
  {
    description:
      "We're sorry to see you go! 😫 If you're sure you want to delete your ExamPadi account, please follow the steps below.",
  },
  {
    title: "Before you proceed",
    list: [
      "Deleting your account is permanent and cannot be undone.",
      "All your progress, data, and subscriptions will be lost.",
      "If you're a subscriber, deleting your account will not automatically cancel your subscription. Please cancel your subscription before deleting your account.",
    ],
    listStyle: "disc",
  },
  {
    title: "How to delete your account",
    list: [
      "Log in to your ExamPadi account.",
      'Go to the "Settings" or "Account" section.',
      'Scroll down to the "Delete Account" or "Delete My Account" button.',
      'Confirm that you want to delete your account by clicking "Delete" or "Yes, delete my account"',
    ],
  },
  {
    title: "Alternative option",
    description:
      'If you\'re having trouble finding the "Delete Account" button, you can:',
    list: [
        "Contact our support team at support@exampadi.ng or through our in-app support chat.",
        "Request account deletion and provide your account email address or username."
    ],
  },
  {
    title: "What happens after deletion",
    list: [
        "Your account will be permanently deleted, and you'll no longer be able to access it.",
        "All your data, including progress, scores, and subscriptions, will be removed from our servers.",
        "You'll no longer receive emails or notifications from ExamPadi."
    ],
    listStyle: "disc",
  },
  {
    title: "Still need help?",
    description:
      "If you have any questions or concerns, please don't hesitate to contact our support team. We're here to help.",
  },
  {
    title: "Remember",
    description:
      "Deleting your account is permanent, so please make sure you're certain before proceeding.",
      priority: true
  },
];

export default data;
