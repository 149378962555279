import {
  HomeOutlined,
  QuestionOutlined,
  PlayCircleOutlined,
  ContactsOutlined,
  DatabaseOutlined,
  TabletOutlined
} from "@ant-design/icons";

export const LandingMenuList = [
  {
    title: "Home",
    to: "/",
    icon: <HomeOutlined />,
  },
  {
    title: "About Us",
    to: "/about",
    icon: <DatabaseOutlined />,
  },
  {
    title: "Subscription",
    to: "/subscription",
    icon: <PlayCircleOutlined />,
  },
  {
    title: "FAQ",
    to: "/faq",
    icon: <QuestionOutlined />,
  },
  {
    title: "Buy ExamPadi Tab",
    to: "/exampadi-tab",
    icon: <TabletOutlined />
    
  },
  {
    title: "Contact Us",
    to: "/contact",
    icon: <ContactsOutlined />,
  },
];

export const FooterMenuList = [
  {
    title: "Subscription Plans",
    to: "/subscription",
  },
  {
    title: "About Us",
    to: "/about",
  },
  {
    title: "Contact Us",
    to: "/contact",
  },
  {
    title: "FAQ",
    to: "/faq",
  },
  {
    title: "Terms & Conditions",
    to: "/terms-and-conditions",
  },
  {
    title: "Privacy Policy",
    to: "/privacy-policy",
  },
];
