import React from "react";
// import { Layout } from "antd";
import { LayoutWrap } from "./styles";
import LandingFooter from "../LandingLayout/LandingFooter";
import "antd/dist/antd.css";

const EmailVerificationLayout = ({ children }) => {
  // const { Content } = Layout;

  return (
    <LayoutWrap>
      <div className="layout">
        <main className="layout__email">{children}</main>
        <LandingFooter />
      </div>
    </LayoutWrap>
  );
};

export default EmailVerificationLayout;
