import React, { createContext, useEffect, useState } from "react";

export const ShopContext = createContext();


async function encryptObject(obj, password) {
  const iv = crypto.getRandomValues(new Uint8Array(12)); // Generate a random IV
  const encoder = new TextEncoder();
  const keyMaterial = await crypto.subtle.importKey(
    "raw",
    encoder.encode(password),
    "PBKDF2",
    false,
    ["deriveKey"]
  );
  const key = await crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: encoder.encode("a-unique-salt"),
      iterations: 100000,
      hash: "SHA-256",
    },
    keyMaterial,
    { name: "AES-GCM", length: 256 },
    false,
    ["encrypt"]
  );
  const encrypted = await crypto.subtle.encrypt(
    { name: "AES-GCM", iv: iv },
    key,
    encoder.encode(JSON.stringify(obj))
  );

  const encryptedData = new Uint8Array(encrypted);
  const combined = new Uint8Array(iv.byteLength + encryptedData.byteLength);
  combined.set(iv);
  combined.set(encryptedData, iv.byteLength);

  return btoa(String.fromCharCode.apply(null, combined)); // Convert to Base64 for storage
}

async function decryptObject(encryptedBase64, password) {
  const combined = Uint8Array.from(atob(encryptedBase64), (c) =>
    c.charCodeAt(0)
  ); // Convert from Base64
  const iv = combined.slice(0, 12);
  const encrypted = combined.slice(12);

  const encoder = new TextEncoder();
  const keyMaterial = await crypto.subtle.importKey(
    "raw",
    encoder.encode(password),
    "PBKDF2",
    false,
    ["deriveKey"]
  );
  const key = await crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: encoder.encode("a-unique-salt"),
      iterations: 100000,
      hash: "SHA-256",
    },
    keyMaterial,
    { name: "AES-GCM", length: 256 },
    false,
    ["decrypt"]
  );
  const decrypted = await crypto.subtle.decrypt(
    { name: "AES-GCM", iv: iv },
    key,
    encrypted
  );

  return JSON.parse(new TextDecoder().decode(decrypted));
}

const ShopProvider = ({ children }) => {
  const initialState = {
    quantity: 1,
    totalAmount: 0,
    selectedProduct: null,
    items: [],
    shipping: "",
    billingAddress: {
      firstname: { value: "", required: true },
      lastname: { value: "", required: true },
      companyName: { value: "", required: false },
      email: { value: "", required: true },
      phoneNumber: { value: "", required: true },
      country: { value: "", required: true },
      state: { value: "", required: true },
      town: { value: "", required: true },
      street: { value: "", required: true },
    },
    otherNotes: { value: "", required: false},
    orderSummary: [{title: "", value: ""}]
  };

  const [cartInfo, setCartInfo] = useState(initialState);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const storedState = sessionStorage.getItem("exampadi-cart");
    if (storedState) {
      decryptObject(storedState, process.env.REACT_APP_ENCRYPTION_PASSWORD)
        .then((decryptedState) => {
          setCartInfo(decryptedState);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Decryption failed:", error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      encryptObject(cartInfo, process.env.REACT_APP_ENCRYPTION_PASSWORD)
        .then((encryptedState) => {
          sessionStorage.setItem("exampadi-cart", encryptedState);
        })
        .catch((error) => console.error("Encryption failed:", error));
    }
  }, [cartInfo, isLoading]);

  const currencyFormatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  });

  return (
    <ShopContext.Provider value={{ cartInfo, setCartInfo, isLoading, currencyFormatter }}>
      {children}
    </ShopContext.Provider>
  );
};

export default ShopProvider;
